import React, {useEffect, useReducer, useRef, useState} from "react";
import Button from "react-bootstrap/Button";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import { onError } from "../../libs/errorLib";
import "./JobsList.css";
import "./JobDetail.css";
import Api from "../../libs/api";
import {AppContext, useAppContext} from "../../libs/contextLib";
import {JobContext, useJobContext} from "../../libs/jobContextLib";
import {
    Accordion,
    Alert,
    Badge,
    Card,
    Col,
    Container, Modal,
    OverlayTrigger,
    Row,
    Tooltip
} from "react-bootstrap";
import TTAvatar from "../../components/TTAvatar";
import Moment from "react-moment";
import _ from "lodash";
import Images from "../../libs/image";
import { getUsername } from '../../libs/models';
import JobDetailAreas from "./JobDetailAreas";
import AreaTaskView from "./AreaTaskView";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "@material-ui/core";
import ShareJob from "../../components/ShareJob";
import {hardenFunkyJob} from "../../libs/models";
import {tapeDeepLinkReducer} from "./Reducers";
import {SET_AREA, SET_JOB, SET_TASK_TAPE, SET_TASK_VIEW, updateDeepLink} from "./DeepLinkActions";
import Loading from "../../components/Loading";

export default function JobDetail() {

    const jobUuid = useRouteMatch('/job/detail/:job_uuid').params.job_uuid;
    let areaUuid = useRouteMatch('/job/detail/:job_uuid/:area_uuid')?.params.area_uuid;
    let viewUuid = useRouteMatch('/job/detail/:job_uuid/:area_uuid/:view_uuid')?.params.view_uuid;
    const tapeUuid = useRouteMatch('/job/detail/:job_uuid/:area_uuid/:view_uuid/:tape_uuid')?.params.tape_uuid;

    const { deviceUuid, isAuthenticated, isReceivingShare, setIsReceivingShare, user } = useAppContext();
    const [deepLinkIds, dispatch] = useReducer(tapeDeepLinkReducer, []);
    const api = Api();
    const history = useHistory();

    const [acceptShareFailed, setAcceptShareFailed] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [jobDetail, setJobDetail] = useState([]);
    const [selectedTaskView, setSelectedTaskView] = useState(null);
    const [shareUuid, setShareUuid] = useState(null);
    const [showJobShare, setShowJobShare] = useState(false);
    const location = useLocation();
    const images = Images();

    function linkHandler(newJobDetail) {

        dispatch({ type: SET_TASK_TAPE, payload: tapeUuid });

        //For some dumb fuck reason I didn't include tapeAnchorUuid in the reply/notification markup
        // So we have to go fetch it from the data model :/
        //If we have a tape, we can get the area and view from that and don't need to rely
        // on the url. This is mainly for "click notifications" case.
        if (tapeUuid) {
            const allAreas = newJobDetail.locations;
            const allTaskViews = _.flatMap(allAreas, "tapeAnchors");
            const allTapes = _.flatMap(allTaskViews, "tapes")
            const selectedTape = _.find(allTapes, { tapeUuid: tapeUuid });
            areaUuid = selectedTape.locationUuid;
            viewUuid = selectedTape.tapeAnchorUuid;
        }
        dispatch({ type: SET_AREA, payload: areaUuid });
        dispatch({ type: SET_TASK_VIEW, payload: viewUuid });

        console.log(`Job Detail loading with job: ${JSON.stringify(deepLinkIds)}`);

    }

    useEffect(() => { //IF no args then run only once per render I think?
        dispatch({ type: SET_JOB, payload:  jobUuid });

        if (location.state) {
            console.log(`JOB DETAIL GOT SHARE ID FROM STATE: ${location.state.share_uuid}`);
            setShareUuid(location.state.share_uuid);
        }

        if (jobUuid) {
            api.getJob(jobUuid, (!isAuthenticated && isReceivingShare)).then((jobDetailLoaded) => {
                hardenFunkyJob(jobDetailLoaded);
                setJobDetail(jobDetailLoaded);
                linkHandler(jobDetailLoaded);
                console.debug(`Received job detail ${JSON.stringify(jobDetailLoaded)}`);
                setIsLoading(false);
            }).catch((e) => {
                console.error(e);
                onError(e);
                setIsLoading(false);
            });
        }

    }, [jobUuid])


    function confirmShare() {
        setIsLoading(true);
        window.scrollTo(0, 0);
        if (!isAuthenticated) {
            setAcceptShareFailed(true);
            setIsLoading(false);
            return;
        }
        api.confirmShare(shareUuid, deviceUuid)
            .then((share) => {
                setIsReceivingShare(false);
                history.push(`/jobs/list`);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(`Problem receiving share ${err}`);
                setAcceptShareFailed(true);
                setIsLoading(false);
            });
    }

    const renderAddressTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {jobDetail.address.streetAddress}<br />
            {jobDetail.address.city} {jobDetail.address.state}, {jobDetail.address.postCode}
        </Tooltip>
    );

    return (
        (isLoading && <Loading />)
        ||
        (!isLoading &&
        <>
            {acceptShareFailed && isAuthenticated &&
            <Alert variant="warning">
                We're sorry for the inconvenience. There was a problem adding you to the job. Please ask the job owner to resend you the share.
            </Alert>
            }
            {acceptShareFailed && !isAuthenticated &&
            <Alert variant="danger">
                <span style={{display: "inline-block"}}>

                    Please <Button style={{backgroundColor: "#5ea514", borderColor: "#5ea514"}}
                        onClick={() => { history.push({
                            pathname: `/signup`,
                            state: { share: location.state.share }
                        })}}>register</Button> or
                    &nbsp;<Button
                            onClick={() => { history.push({
                                pathname: `/login`,
                                state: { share_uuid: location.state.share_uuid }
                            })}}>login</Button>
                    &nbsp;before accepting the Job Share.
                </span>
            </Alert>
            }
            <JobContext.Provider value={{ jobDetail, deepLinkIds, dispatch }}>
                <Container>
                    <Row>
					<div className="upper-top-bar">

                        <Col>
                            <div className={'tt-share-icon'}>
                                {/* Share Link - TODO: This modal wrapper of the compnent might itself be better off as a component*/}
                                <FontAwesomeIcon icon="share-square" color="#1a67d3" style={{cursor: "pointer"}} size="2x"
                                                 onClick={() => setShowJobShare(true)}/>
                                <Modal
                                  show={showJobShare}
                                  onHide={() => setShowJobShare(false)}
                                  dialogClassName="modal-90w"
                                  size="md"
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Sharing {jobDetail.name}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <ShareJob userRoleInJob={jobDetail.userRoleInJob} jobUuid={jobDetail.jobUuid} jobName={jobDetail.name} closeModal={() => setShowJobShare(false)}  />
                                    </Modal.Body>
                                    <Modal.Footer
                                      style={{
                                          justifyContent: "center",
                                          margin: "0 auto",
                                          width: "100%"
                                      }}
                                    >
                                        <p>
                                            On our desktop app, you can only share via email. Please enter email above and we'll send
                                            them a notification to join this job. If you would like to share by Text Message (SMS)
                                            please:
                                        </p>
                                        <p style={{alignContent: "center"}}><a href="https://tasktape.com" target="_new">Download our mobile app</a></p>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                            <div className={'tt-map-icon'}>
                                {/* MAP Link*/}
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderAddressTooltip}
                                >
                                    {
                                        (
                                            (jobDetail.address && jobDetail.address.geolocation && jobDetail.address.geolocation.lat > 0 &&
                                                <a href={'https://www.google.com/maps/search/?api=1&query=' + jobDetail.address.geolocation.lat + ',' + jobDetail.address.geolocation.lng} target="_new">
                                                    <FontAwesomeIcon icon="map-marked-alt" color="#1a67d3" style={{cursor: "pointer"}} size="2x"/>
                                                </a>)
                                            ||
                                            <span>&nbsp;</span>
                                        )
                                    }
                                </OverlayTrigger>
                            </div>
                        </Col>
                        <Col align={"left"}>
                            <TTAvatar user={images.buildUserForAvatar(jobDetail)} />&nbsp;
                            {jobDetail.name}&nbsp;&nbsp;
                            {jobDetail.userRoleInJob !== 'ROLE_OWNER' && <Badge pill variant="warning">YOU ARE A VIEWER</Badge>}
                        </Col>
                        <Col align={"center"}>
                            <span className="created-by">
                                Created on <Moment format="YYYY-MM-DD">{new Date(jobDetail.createdOn).toLocaleDateString()}</Moment> by {getUsername(images.buildUserForAvatar(jobDetail))}
                            </span>
                        </Col>
						             
                    </div>
                    <div className="address-upper" style={{cursor: 'pointer'}}>
                        <Col align={"center"} className="address-no">
                            <Accordion>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey="0">
                                            Job Description &gt;
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body><span style={{whiteSpace: "pre", fontSize: 12, color: "black"}}>{jobDetail.jobImage.description}</span></Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Col>
                    </div>
                    </Row>
                {
                    isReceivingShare &&
                    <Row>
                        <Col sm={2}>
                            <img src="/icons/icons-handshake.png" srcSet="/icons/icons-handshake@2x.png 2x,/icons/icons-handshake@3x.png 3x" className="iconshandshake"/>
                        </Col>
                        <Col sm={8}>
                            <div className="join-rect" onClick={() => confirmShare()}>
                                <p><span className="Tap-To-Join-Job align-middle">Tap To Join Job</span></p>
                                <span className="This-will-place-the">This will place the job in your Job List</span>
                            </div>
                        </Col>
                        <Col sm={2}>
                            <img src="/icons/icons-handshake.png" srcSet="/icons/icons-handshake@2x.png 2x,/icons/icons-handshake@3x.png 3x" className="iconshandshake"/>
                        </Col>
                    </Row>

                }
                    <Row>
                        &nbsp;
                    </Row>
			
                </Container>

                <Container fluid>
                    {jobDetail.locations && jobDetail.locations.length > 0 &&
                    <Row>
                        <Col md={4}>
                            <JobDetailAreas
                                areas={jobDetail.locations}
                                taskViewSelected={setSelectedTaskView}
                            />
                        </Col>
                        <Col md={8} className="right-side-bar">
                            <AreaTaskView selectedTaskView={selectedTaskView} />
                        </Col>
                    </Row>
                    }
                    {(jobDetail.locations === undefined || jobDetail.locations.length === 0) &&
                    <Row>
                        <Col md={2} />
                        <Col>
                            <Alert variant="info">
                                This job has no Areas and TaskViews!<br />
                                Please use our <Link href="https://www.tasktape.com" variant="button" target="_new">mobile app</Link> to
                                create these, or share the job with a field worker to have them create Areas and TaskViews.
                            </Alert>
                            <ShareJob jobUuid={jobDetail.jobUuid} jobName={jobDetail.name} userRoleInJob={jobDetail.userRoleInJob} />
                        </Col>
                        <Col md={2} />
                    </Row>
                    }
                </Container>
            </JobContext.Provider>
        </>)
    );
}
