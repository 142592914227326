import React from "react";
import "./Feed.css";
import _ from "lodash";
import {Col, Container, Row} from "react-bootstrap";
import NoteReplies from "./NoteReplies";
import ReactAudioPlayer from "react-audio-player";
import NoteHeader from "./NoteHeader";

export default function AudioNote(props) {

    return (
        <>
            <Row>
                <Col><NoteHeader note={props.note} replyNote={props.replyNote}/></Col>
            </Row>
            <Row>
                <Col><hr /></Col>
            </Row>
            <Row>
                <Col>
                    <ReactAudioPlayer
                        src={props.note && props.note.content && props.note.content.length > 0 && props.note.content[0].contentUrl}
                        controls
                    />
                </Col>
            </Row>
        </>
    );
}
/**
 {
                                            "content": [
                                                {
                                                    "contentUrl": "https://s3.amazonaws.com/023464524600-tasktape-dev/users/c1db9926-4a0a-4089-8dbf-ea667eae5434/jobs/8A675488-5A2B-472A-9564-0E395145EB18/locations/FE57C009-2C6E-4F6E-8829-235C375AA4ED/tapes/B96A176F-0122-4BF7-BBE6-565AA9F4D95E/audio_note_F10CF596-1116-4996-8202-662024A7A328.m4a",
                                                    "width": 0,
                                                    "description": " ",
                                                    "mediaUuid": "A71BB5DB-F751-4669-AC65-4088BCB68329",
                                                    "originalUrl": "https://s3.amazonaws.com/023464524600-tasktape-dev/users/c1db9926-4a0a-4089-8dbf-ea667eae5434/jobs/8A675488-5A2B-472A-9564-0E395145EB18/locations/FE57C009-2C6E-4F6E-8829-235C375AA4ED/tapes/B96A176F-0122-4BF7-BBE6-565AA9F4D95E/audio_note_F10CF596-1116-4996-8202-662024A7A328.m4a",
                                                    "createdOn": 1619246388930,
                                                    "createdUser": "c1db9926-4a0a-4089-8dbf-ea667eae5434",
                                                    "height": 0
                                                }
                                            ],
                                            "tapeUuid": "B96A176F-0122-4BF7-BBE6-565AA9F4D95E",
                                            "version": 0,
                                            "createdUserLastName": "Lover",
                                            "createdOn": 1619246398681,
                                            "createdUserFirstName": "John ",
                                            "shareable": true,
                                            "description": "audio_note_F10CF596-1116-4996-8202-662024A7A328.m4a",
                                            "createdUser": "c1db9926-4a0a-4089-8dbf-ea667eae5434",
                                            "noteUuid": "F10CF596-1116-4996-8202-662024A7A328",
                                            "tags": [],
                                            "type": "AUDIO",
                                            "replies": [
                                                {
                                                    "content": " Nice. But not sure what I'm replying to.  It needs a reply above your message not just the thread? ",
                                                    "tapeUuid": "B96A176F-0122-4BF7-BBE6-565AA9F4D95E",
                                                    "version": 1,
                                                    "readBy": [
                                                        "ba41452a-f770-4057-ae40-6f3117d682a1"
                                                    ],
                                                    "createdUserFirstName": "J",
                                                    "jobOwnerUuid": "ba41452a-f770-4057-ae40-6f3117d682a1",
                                                    "createdUser": "ba41452a-f770-4057-ae40-6f3117d682a1",
                                                    "noteUuid": "F10CF596-1116-4996-8202-662024A7A328",
                                                    "deleted": false,
                                                    "replyUuid": "0EA6668D-CF9F-498B-866F-B53471162C8D",
                                                    "createdUserLastName": "H",
                                                    "createdOn": 1619384675369,
                                                    "edited": false,
                                                    "jobUuid": "8A675488-5A2B-472A-9564-0E395145EB18",
                                                    "locationUuid": "FE57C009-2C6E-4F6E-8829-235C375AA4ED"
                                                },
                                                {
                                                    "content": "I'm getting notifications in the blue bell from my own replies ",
                                                    "tapeUuid": "B96A176F-0122-4BF7-BBE6-565AA9F4D95E",
                                                    "version": 1,
                                                    "readBy": [
                                                        "ba41452a-f770-4057-ae40-6f3117d682a1"
                                                    ],
                                                    "createdUserFirstName": "J",
                                                    "jobOwnerUuid": "ba41452a-f770-4057-ae40-6f3117d682a1",
                                                    "createdUser": "ba41452a-f770-4057-ae40-6f3117d682a1",
                                                    "noteUuid": "F10CF596-1116-4996-8202-662024A7A328",
                                                    "deleted": false,
                                                    "replyUuid": "FC8B86C2-C83B-4417-9326-EEAE64F2CC16",
                                                    "createdUserLastName": "H",
                                                    "createdOn": 1619384752590,
                                                    "edited": false,
                                                    "jobUuid": "8A675488-5A2B-472A-9564-0E395145EB18",
                                                    "locationUuid": "FE57C009-2C6E-4F6E-8829-235C375AA4ED"
                                                },
                                                {
                                                    "content": "Yeah that extra notification thing has been in my jira queue for a while",
                                                    "tapeUuid": "B96A176F-0122-4BF7-BBE6-565AA9F4D95E",
                                                    "version": 1,
                                                    "readBy": [
                                                        "ba41452a-f770-4057-ae40-6f3117d682a1"
                                                    ],
                                                    "createdUserFirstName": "Chris+6",
                                                    "jobOwnerUuid": "6df7f44e-8ef7-4217-8d0f-1216900f00ce",
                                                    "createdUser": "6df7f44e-8ef7-4217-8d0f-1216900f00ce",
                                                    "noteUuid": "F10CF596-1116-4996-8202-662024A7A328",
                                                    "deleted": false,
                                                    "replyUuid": "F082456F-8DEC-4FFD-BD6B-888CEBF9314C",
                                                    "createdUserLastName": "Mathias",
                                                    "createdOn": 1619384925059,
                                                    "edited": false,
                                                    "jobUuid": "8A675488-5A2B-472A-9564-0E395145EB18",
                                                    "locationUuid": "FE57C009-2C6E-4F6E-8829-235C375AA4ED"
                                                },
                                                {
                                                    "content": "I was just able to seamlessly login after being forced to from trying to reply while not logged in, and then brought right back here to do my reply. Works great.",
                                                    "tapeUuid": "B96A176F-0122-4BF7-BBE6-565AA9F4D95E",
                                                    "version": 2,
                                                    "readBy": [
                                                        "ba41452a-f770-4057-ae40-6f3117d682a1",
                                                        "ba41452a-f770-4057-ae40-6f3117d682a1"
                                                    ],
                                                    "createdUserFirstName": "Chris+6",
                                                    "jobOwnerUuid": "6df7f44e-8ef7-4217-8d0f-1216900f00ce",
                                                    "createdUser": "6df7f44e-8ef7-4217-8d0f-1216900f00ce",
                                                    "noteUuid": "F10CF596-1116-4996-8202-662024A7A328",
                                                    "deleted": false,
                                                    "replyUuid": "37D679C3-ECA7-4904-BD82-A214353190A9",
                                                    "createdUserLastName": "Mathias",
                                                    "createdOn": 1619377088649,
                                                    "edited": false,
                                                    "jobUuid": "8A675488-5A2B-472A-9564-0E395145EB18",
                                                    "locationUuid": "FE57C009-2C6E-4F6E-8829-235C375AA4ED"
                                                }
                                            ]
                                        }
 ],
 **/