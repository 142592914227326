import React, {useEffect, useRef, useState} from "react";
import { useHistory } from "react-router-dom";
import JobListTable from "../../components/JobListTable";
import { onError } from "../../libs/errorLib";
import Api from "../../libs/api";
import {useAppContext} from "../../libs/contextLib";
import {Col, Container, Image, Modal, Row} from "react-bootstrap";
import CreateJob from "./CreateJob";
import Button from "react-bootstrap/Button";
import _ from "lodash";
import "./JobsList.css";
import {hardenFunkyJob} from "../../libs/models";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Loading from "../../components/Loading";

export default function JobsList() {

    const { isAuthenticating, isAuthenticated, user } = useAppContext();
    const api = Api();

    const [show, setShow] = useState(false);
    const [jobModalEditMode, setJobModalEditMode] = useState(false);
    const [selectedJob, setSelectedjob] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [jobs, setJobs] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = function(jobUuid) {
        if (jobUuid && jobUuid.type && jobUuid.type === 'click') { //This is "add job
            setJobModalEditMode(false);
            setSelectedjob(null);
        } else {
            setSelectedjob(_.find(jobs, { 'jobUuid': jobUuid }));
            setJobModalEditMode(true);
        }
        setShow(true);
    }

    const history = useHistory();

    function addJob(job, isUpdate) {
        if (!isUpdate) {
            jobs.push(job);
        } else{
            let index = _.findIndex(jobs, {jobUuid: job.jobUuid});
            // Replace item at index using native splice
            setJobs(jobs.splice(index, 1, job));
        }
        setJobs(_.sortBy(jobs, ['createdOn']).reverse());
    }

    function deleteJob(jobUuid) {
        api.deleteJob(jobUuid)
            .then(() => {
                console.log(`Job ${jobUuid} deleted`);
                _.remove(jobs, function(job) {
                    if (job.jobUuid === jobUuid ) {
                        return true;
                    }
                });
                setJobs(jobs);
                setShow(false);
            })
            .catch((err) => console.log(`Problem deleting job ${JSON.stringify(err)}`));
    }

    useEffect(() => { //IF no args then run only once per render I think?
        if (!isAuthenticated) {
            history.push(`/login`)
        }

        api.getJobs().then((jobsList) => {
            jobsList = _.forEach(jobsList, function(job) {
                hardenFunkyJob(job);
            });
            jobsList = _.sortBy(jobsList, ['createdOn']).reverse();
            setJobs(jobsList);
            console.debug(`Received ${jobsList.length} jobs`);
            setIsLoading(false);
        });
    }, [])

    return ( 
        (isLoading && <Loading />)
        ||
        (!isLoading && !isAuthenticating &&
            <>
                <Modal
                    show={show}
                    onHide={handleClose}
                    dialogClassName="modal-90w"
                    backdrop="static" 
                    size="xl" className="create-job-modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <FontAwesomeIcon icon="trash-alt" color="#1a67d3" style={{cursor: "pointer", alignContent: "left"}} size="2x" onClick={() => deleteJob(selectedJob.jobUuid)} />
                        <Modal.Title
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "0 auto",
                                width: "100%"
                            }}>
                            <h1 className="mb-3">{jobModalEditMode ? 'Updating' : 'Create'} Job</h1>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CreateJob closeModal={handleClose} selectedJob={selectedJob} addJob={addJob} />
                    </Modal.Body>
                    <Modal.Footer
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}>
                        {jobModalEditMode && selectedJob && <span>Created on: {new Date( selectedJob.createdOn).toLocaleDateString()}</span>}
                    </Modal.Footer>
                </Modal>
                <Container>
                    <Row>
                        <Col className="jobTitlesec">
                            <div>
                                <h1 className="mb-0">Active Jobs</h1>
                            </div>
                        </Col>
                        <Col className="text-right jobRightsec"><Button onClick={handleShow}>Start New Job</Button></Col>
                    </Row>
                    <Row>
                        <Col className="jobListsec" >
                            <div className="JobListRow w-100">
                                <JobListTable jobs={jobs} popJobEditModal={handleShow} deleteJob={deleteJob} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>)

    );
}
