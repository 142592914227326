import React, {useContext, useEffect, useState} from "react";
import "./Feed.css";
import Form from "react-bootstrap/Form";
import LoaderButton from "../LoaderButton";
import {FeedContext} from "../../libs/feedContextLib";
import {Col, Row} from "react-bootstrap";
import TTAvatar from "../TTAvatar";
import Moment from "react-moment";
import {Delete, Edit} from "@material-ui/icons";
import Authorization from "../../libs/authorization";
import Images from "../../libs/image";
import {
    CANCEL_EDIT_REPLY,
    DELETE_REPLY,
    EDIT_REPLY, noteActions,
    PUT_REPLY, SELECT_NOTE
} from "../../containers/tasktape/NoteInterfaceActions";
import ImageNoteContent from "./ImageNoteContent";
import _ from "lodash";
import Linkify from "react-linkify";

export default function NoteReplyContent(props) {

    const { feed, dispatch } = useContext(FeedContext);
    const [reply, setReply] = useState(props.reply);
    const [noteContent, setNoteContent] = useState(props.reply.content);
    const [note, setNote] = useState(props.note);

    const replyAuth = Authorization().replies;
    const images = Images();

    useEffect(() => {
        setReply(props.reply);
        setNote(props.note);
    }, [props.reply, props.note]);

    function validateReply() {
        alert("NOte valid");
        return false;
    }

    function isActiveReply() {
        return feed.activeReply?.replyUuid === reply.replyUuid;
    }

    async function saveReply(e) {
        e.preventDefault();
        dispatch({type: SELECT_NOTE, payload: note});
        reply.content = noteContent;
        dispatch({ type: PUT_REPLY, payload: reply })
        await noteActions.putReply(reply);
        dispatch({ type: CANCEL_EDIT_REPLY });
    }

    async function deleteReply(e) {
        e.preventDefault();
        dispatch({ type: DELETE_REPLY, payload: reply, note: note });
        await noteActions.deleteReply(reply);
        _.remove(note.replies, function (thisReply) {
            if (thisReply.replyUuid === reply.replyUuid) {
                return true;
            }
        });
        dispatch({ type: CANCEL_EDIT_REPLY });
    }

    function cancelEdit(e) {
        e.preventDefault();
        if (feed.editingReply) {
            props.note.replies.pop();
        }
        dispatch({ type: CANCEL_EDIT_REPLY });
    }

    return (
        <>
            <Row key={reply.replyUuid}>
                <Col md={1}></Col>
                <Col md={11}>
                    <Row>
                        <Col className="modal-reply-main">
                            <TTAvatar size={30} user={images.buildUserForAvatar(reply)} />
                            <span className="px-2" style={ {fontSize: 16, textAlign:"right", verticalAlign: "bottom"} }>
                                <Moment format="hh:mm A on MM.DD.YY">{reply?.createdOn}</Moment>
                            </span>
							    <span className="float-right">
                        {
                            replyAuth.canEdit(reply) && !feed.saving &&  <span className="px-2"><Edit fontSize="inherit" color="primary" style={{ cursor: 'pointer' }} onClick={() => dispatch({ type: EDIT_REPLY, payload: reply })} /></span>
                        }
                        {
                            replyAuth.canDelete(reply) && !feed.saving &&  <span className="px-2"><Delete fontSize="inherit" color="primary" style={{ cursor: 'pointer' }} onClick={(e) => deleteReply(e)} /></span>
                        }
                        </span>
                        </Col>
                    
                    </Row>
                
                    <Row>
                        <Col className="modal-reply-text">

                            {(!(reply.content instanceof Array) && !isActiveReply()) &&
                            <Linkify
                              componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a target="blank" href={decoratedHref} key={key}>
                                    {decoratedText}
                                </a>
                              )}
                            ><span style={{whiteSpace: "pre"}}>{reply.content}</span></Linkify>

                            }

                            {(reply.content instanceof Array && !isActiveReply()) &&
                            <ImageNoteContent note={{ content: reply.content }} />
                            }

                            {(feed.editingReply && isActiveReply()) &&
                            <Form>
                                <Form.Group controlId="replyText" size="lg">
                                    <Form.Control
                                        autoFocus
                                        as="textarea"
                                        rows={3}
                                        onChange={e => setNoteContent(e.target.value)}
                                        disabled={feed.saving}
                                        value={noteContent}
                                    />
                                </Form.Group>
                                <LoaderButton
                                    size="md"
                                    type="save"
                                    variant="primary"
                                    isLoading={feed.saving}
                                    disabled={!validateReply}
                                    onClick={(e) => saveReply(e)}
                                >
                                    Save
                                </LoaderButton>

                                <LoaderButton
                                    size="md"
                                    type="save"
                                    variant="light"
                                    onClick={(e) => cancelEdit(e)}
                                >
                                    Cancel
                                </LoaderButton>
                            </Form>
                            }
                        </Col>
                    </Row>
       
                </Col>
            </Row>
        </>
    );
}
