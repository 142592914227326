import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Auth } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import {useHistory} from "react-router";
import {Nav} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";

export default function ForgotPassword() {
    const history = useHistory();
    const { userHasAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);

    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: "",
        confirmationCode: ""
    })

    function validateForm() { // TODO: Toast "bad inputs" https://react-bootstrap.github.io/components/toasts/
        return fields.email.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);

        try {
            await Auth.forgotPassword(fields.email);
            console.debug("User password triggered. Showing confirm page");
            setIsConfirming(true);
            renderConfirmationForm();
            setIsLoading(false);
        } catch (e) {
            console.error(e);

            // if (e.code === "UserNotConfirmedException") {
            //     console.debug(`User missing confirmation.Resending code to ${fields.email} `);
            //     await Auth.resendSignUp(fields.email);
            //     console.debug("Confirmation code resent");
            //     setIsLoading(false);
            //     setIsConfirming(true);
            // } else {
                onError(e);
                setIsLoading(false);
            // }

        }
    }


    function validateConfirmationForm() {
        return fields.confirmationCode.length > 0 && fields.password.length > 0;
    }

    async function handleConfirmationSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            await Auth.forgotPasswordSubmit(fields.email, fields.confirmationCode, fields.password);

            console.debug("Forgot Password successful. Signing in");

            await Auth.signIn(fields.email, fields.password);

            userHasAuthenticated(true);

            console.debug("Post-Confirm User sign in successful. Redirecting to Jobs List");
            history.push("/jobs/list");

        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    return (
        <>
		<style>
                    {`
                   body, html {
    height: 100%;
}
body {
	background-image:url("images/login-bg.png");
	background-repeat:no-repeat;
	background-size:cover;
	}
 nav.mb-3.navbar.navbar-expand-md.navbar-light.bg-light {
    display: none;
}
.loginForm p {
    font-size: 16px;
    color: #000;
    line-height: 22px;
}
@media (max-width:480px) {
	.loginPage {
    padding: 0 15px;
}
}
                `}
                </style>
            {isConfirming === false ? renderForgotPasswordForm() : renderConfirmationForm()}
        </>
    )

    function renderConfirmationForm() {

        return (
            <div className="Login loginPage loginForm">
                <Form onSubmit={handleConfirmationSubmit}>
                    <Form.Text>
                        <h3>Reset your password - Confirmation</h3>
                    </Form.Text>

                    <Form.Group controlId="confirmationCode" size="lg">
                        <Form.Text muted>Please check your email or text messages for the code.</Form.Text>
                        <Form.Label>Confirmation Code</Form.Label>
                        <Form.Control
                            autoFocus
                            type="tel"
                            onChange={handleFieldChange}
                            placeholder="confirmation code"
                            disabled={isLoading}
                            value={fields.confirmationCode}
                        />

                    </Form.Group>
                    <Form.Group size="lg" controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={fields.password}
                            placeholder="password"
                            disabled={isLoading}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                    <LoaderButton
                        block
                        size="sm"
                        type="submit"
                        isLoading={isLoading}
                        disabled={!validateConfirmationForm()}
                    >
                        Verify
                    </LoaderButton>
                </Form>
            </div>
        );
    }

    function renderForgotPasswordForm() {
        return (
            <div className="Login loginPage">
			<div className="loginImage">
                <img src='/icons/logo.png'/>
                </div>
                <div className="loginForm forgotform">
                    <Form onSubmit={handleSubmit}>
                        <Form.Text>
                            <h3>Reset your password</h3>
                            <span className="info">Enter your email address if we can find one that matches, we will send you a confirmation code
                                to help reset your password.</span>
                        </Form.Text>
                        <Form.Group size="lg" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                autoFocus
                                type="email"
                                value={fields.email}
                                placeholder="email"
                                disabled={isLoading}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <LoaderButton
                            block
                            size="lg"
                            type="submit"
                            isLoading={isLoading}
                            disabled={!validateForm()}
                        >
                            Send Code
                        </LoaderButton>
                        <Form.Text muted>
                        <span>
                            <LinkContainer to="/login">
                                <Nav.Link>Back to Login</Nav.Link>
                            </LinkContainer>
                        </span>
                        </Form.Text>
                    </Form>
                </div>
            </div>
        )
    }
}