import {createContext, useReducer, useEffect, useContext} from "react";
import * as React from 'react';
import {notesReducer} from "../containers/tasktape/Reducers";

export const FeedContext = createContext();

const FeedContextProvider = props => {
    const [feed, dispatch] = useReducer(notesReducer, {}, () => {
        const localData = localStorage.getItem('feed');
        return localData ? JSON.parse(localData) : {};
    }); //useReducer accepts a reducer of type (state,action)=> NewState,and returns the new state passed with a dispatch method

    useEffect(() => {
        // console.log(props.children);
        localStorage.setItem('feed', JSON.stringify(feed));
    }, [feed]);

    return (
      <FeedContext.Provider value={{ feed, dispatch }}>
          {props.children}
      </FeedContext.Provider>
    );
};

export default FeedContextProvider;