import { SET_JOB, SET_AREA, SET_TASK_VIEW, SET_TASK_TAPE } from './DeepLinkActions';
import { SELECT_TAPE, SELECT_NOTE, PUT_NOTE, EDIT_NOTE, CANCEL_EDIT_NOTE, NEW_NOTE, DELETE_NOTE, PUT_REPLY, EDIT_REPLY, CANCEL_EDIT_REPLY, NEW_REPLY, DELETE_REPLY, noteActions } from './NoteInterfaceActions';

function tapeDeepLinkReducer(state = {}, action) {
    switch (action.type) {
        case SET_JOB:
            state.jobUuid = action.payload;
            return state;
        case SET_AREA:
            state.areaUuid = action.payload;
            return state;
        case SET_TASK_VIEW:
            state.taskViewUuid = action.payload;
            return state;
        case SET_TASK_TAPE:
            state.taskTapeUuid = action.payload;
            return state;
        default:
            return state
    }
}
export { tapeDeepLinkReducer };

function notesReducer(state, action) {
    noteActions.state = state;

    switch (action.type) {
        case SELECT_TAPE:
            if (action.user === undefined) {
                console.error("Reducers.js: User is required when selecting tape");
                throw new Error("User is required when selecting tape"); //Super annoying this isn't reported
            }

            //Wipe any existing storage when tape selected.
            localStorage.removeItem('feed');

            return {
                ...state,
                activeTape: action.payload,
                user: action.user,
                editingNote: false,
                addingNote: false,
                editingReply: false,
                saving: false,
                activeNote: undefined
            };

        case SELECT_NOTE:
            return {
                ...state,
                activeNote: action.payload,
                editingNote: false,
                addingNote: false,
                saving: false
            }

        case PUT_NOTE:
            return {
                ...state,
                activeNote: action.payload,
                saving: true,
            }

        case EDIT_NOTE:
            return {
                ...state,
                activeNote: action.payload,
                editingNote: true,
                addingNote: false,
                saving: false
            }

        case CANCEL_EDIT_NOTE:
            return {
                ...state,
                activeNote: undefined,
                editingNote: false,
                addingNote: false,
                saving: false
            }

        case NEW_NOTE:
            return {
                ...state,
                activeNote: noteActions.newNote(action.noteType),
                editingNote: false,
                addingNote: true
            };

        case DELETE_NOTE:
            return {
                ...state,
                activeNote: action.payload,
                saving: true
            }

      //TODO: SHoudl these be broken up FFS?
        case PUT_REPLY:
            return {
                ...state,
                activeReply: action.payload,
                saving: true
            }

        case EDIT_REPLY:
            return {
                ...state,
                activeReply: action.payload,
                editingReply: true
            }

        case CANCEL_EDIT_REPLY:
            return {
                ...state,
                activeReply: undefined,
                editingReply: false,
                saving: false
            }

        case NEW_REPLY:
            return {
                ...state,
                editingReply: true,
                activeReply: action.payload
            }

        case DELETE_REPLY:
            return {
                activeReply: action.payload,
                activeNote: action.note,
                saving: true
            }

        default:
            throw new Error("Type is required");
    }
}
export { notesReducer };