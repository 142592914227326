
const SET_JOB = 'SET_JOB';
const SET_AREA = 'SET_AREA';
const SET_TASK_VIEW = 'SET_TASK_VIEW';
const SET_TASK_TAPE = 'SET_TASK_TAPE';

function updateDeepLink(history, deepLinkIds) {
    let path;
    if (deepLinkIds.taskTapeUuid) {
        path = `/job/detail/${deepLinkIds.jobUuid}/${deepLinkIds.areaUuid}/${deepLinkIds.taskViewUuid}/${deepLinkIds.taskTapeUuid}/`;
    } else if (deepLinkIds.taskViewUuid) {
        path = `/job/detail/${deepLinkIds.jobUuid}/${deepLinkIds.areaUuid}/${deepLinkIds.taskViewUuid}`;
    } else if (deepLinkIds.areaUuid) {
        path = `/job/detail/${deepLinkIds.jobUuid}/${deepLinkIds.areaUuid}`;
    } else if (deepLinkIds.jobUuid) {
        path = `/job/detail/${deepLinkIds.jobUuid}`;
    }
    history.push({
        pathname: path
    });
}

export { SET_JOB, SET_AREA, SET_TASK_VIEW, SET_TASK_TAPE, updateDeepLink };