const dev = {
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://kj54vglkgj.execute-api.us-east-1.amazonaws.com/dev",
        apiName: "TasktapeAPI"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_7lRT4FVko",
        APP_CLIENT_ID: "5qf8b8tgev1tp5fltl2q0phul1",
        IDENTITY_POOL_ID: "us-east-1:143fc92e-b0a2-400a-9b8e-66f26d4f0154",
    },
    aws: {
        REGION: "us-east-1",
        s3JobBucket: "023464524600-tasktape-dev",
        s3ResizeBucket: "023464524600-tasktape-dev-images",
        s3Url: "https://d13yjimnef47qn.cloudfront.net"
    },
    geoapify: {
        apiKey: "c176b3836fd34c069c949dde363bdb4c"
    }


};

const prod = {
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://96a56qpmd8.execute-api.us-east-1.amazonaws.com/prod",
        apiName: "TasktapeAPI"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_AVEcFVsjX",
        APP_CLIENT_ID: "1k74nvjdrg4bdb481u1ll15mls",
        IDENTITY_POOL_ID: "us-east-1:94fe68f5-1ca5-4881-a3dd-e49e1e9b1876"
    },
    aws: {
        REGION: "us-east-1",
        s3JobBucket: "023464524600-tasktape-prod",
        s3ResizeBucket: "023464524600-tasktape-prod-images",
        s3Url: "https://d21g0p1ddc7ywl.cloudfront.net"
    },
    geoapify: {
        apiKey: "c176b3836fd34c069c949dde363bdb4c"
    }

};

const config = {
    SHARED_CONFIG_VALUE: 1,

    //Dev default with ability for prod.

    ...(process.env.REACT_APP_STAGE === "prod" ? prod : dev),

};

console.log(`Starting up with REACT STAGE: ${process.env.REACT_APP_STAGE}`);
export default config;