/**
 *
 * From:  https://solipsar.atlassian.net/wiki/spaces/TASK/pages/8060994/Image+Processing
 *

 Client code should take this URL...

 https://s3.amazonaws.com/023464524600-tasktape/users/6a46234e-ea2f-41e8-87a2-975c568b0c5d/jobs/09C38FCC-2954-4EF3-A7D1-BF9C7F9B361B/job.jpg

 and construct an image access URL by:

 replacing the bucket-name 023464524600-tasktape with the images bucket name `023464524600-tasktape-images` (but honor the -dev for dev case 023464524600-tasktape-dev-images)
 prepending the desired size, e.g. /800/ for "width adjusted, height adapted" output, or /800x386/ for a center-cropped version of the image adapted to that resolution (shrink-then-crop)
 Swapping out the domain for the web-host version of the url, i.e. https://023464524600-tasktape-images.s3-website-us-east-1.amazonaws.com

 **/
import config from '../config';
import Api from "./api";

function Images() {

    const api = Api();
    const functions = {};

    functions.buildUserForAvatar = function(entity) {

        // TODO: Make this use CDN like `${config.aws.s3Url}/${dimensionsString}/${path}`;
        const profile_url = `https://s3.${config.aws.REGION}.amazonaws.com/${config.aws.s3JobBucket}/${functions.getProfileImageStorageKey(entity?.createdUser)}`;

        return {
            firstName: entity?.createdUserFirstName,
            lastName: entity?.createdUserLastName,
            userUuid: entity?.createdUser,
            profile: {
                profile_image_url: profile_url
            }
        }
    }

    functions.getNoteImageStorageKey = function(user, jobUuid, locationUuid, tapeUuid, mediaUuid) {
        ///users/c1db992/jobs/8A675488/locations/FE57C009-2C6E-4F6E-8829-235C375AA4ED/tapes/B96A176F-0122-4BF7-BBE6-565AA9F4D95E/image_note_5D4B1BB4-8AA1-411F-BA65-3CEA2FABC5DB_003.jpg",
        return `users/${user.userUuid}/jobs/${jobUuid}/locations/${locationUuid}/tapes/${tapeUuid}/image_note_${mediaUuid}.jpg`;
    }

    functions.getJobImageStorageKey = function(user, jobUuid) {
        return `users/${user.userUuid}/jobs/${jobUuid}/job-${jobUuid}.jpg`;
    }

    functions.getProfileImageStorageKey = function(userUuid) {
        ///users/05d06106-1728-4d08-b8c6-8a03bf8f3f58/profile/profile_avatar.jpg
        return `users/${userUuid}/profile/profile_avatar.jpg`;
    }

    functions.getUrlForResizedImage = function(contentUrl, width, height) {

        width = Math.round(width);
        height = Math.round(height);
        //Conform stock s3.amazonaws pointer to qualified URL that pulls through resizer function & is public
        if (contentUrl && contentUrl.length > 0 && contentUrl.indexOf("/") > -1) {
            const parts = contentUrl.split("/");

            let path = "";

            if (contentUrl.indexOf("http") < 0) { //In case content wasn't stored with protocol :/
                path = parts.slice(1, parts.length).join("/");
            } else if (contentUrl.indexOf(config.aws.s3JobBucket) > 0) { //In case content was stored with bucket in URI
                path = parts.slice(4, parts.length).join("/");
            } else {
                path = parts.slice(3, parts.length).join("/");
            }

            const dimensionsString = height ? `${width}x${height}` : `${width}`;
            console.debug(`Width${width}xHeight${height} - for Original URL${JSON.stringify(contentUrl)}`);
            return `${config.aws.s3Url}/${dimensionsString}/${path}`;

        } else {
            //Bogus image
            return `/icons/tape-icon-drag@3x.png`;
        }
    }

    functions.getStorageURL = function(path) {
        //https://s3.us-east-1.amazonaws.com/023464524600-tasktape-dev/users/6df7f44e-8ef7-4217-8d0f-1216900f00ce/jobs/08878bff-9491-48cc-83e5-798c88591c8d/job-08878bff-9491-48cc-83e5-798c88591c8d.jpg
        return `https://s3.${config.aws.REGION}.amazonaws.com/${config.aws.s3JobBucket}/${path}`;
    }

    functions.getUrlForOriginal = function(contentUrl) {

        // Conform stored "content url" to accessible signed image url
        // https://s3.amazonaws.com/023464524600-tasktape-dev-images/users/6df7f44e-8ef7-4217-8d0f-1216900f00ce/jobs/E49194DB-760C-4A95-9939-AF43B2DD2368/job-E49194DB-760C-4A95-9939-AF43B2DD2368.jpg
        if (contentUrl && contentUrl.length > 0 && contentUrl.indexOf("/") > -1) {
            const parts = contentUrl.split("/");

            if (contentUrl.indexOf("http") < 0) { //In case content wasn't stored with protocol :/
                const bucket = parts.slice(0,1)[0];

                const remainingPath = parts.slice(1, parts.length).join("/");

                return `https://s3.${config.aws.REGION}.amazonaws.com/${bucket}/${remainingPath}`;
                // const url = await api.getObjectAsSignedUrl(bucket, remainingPath);
                // return url;

            } else {
                const bucket = parts.slice(3,4)[0];

                const remainingPath = parts.slice(4, parts.length).join("/");

                return `https://s3.${config.aws.REGION}.amazonaws.com/${bucket}/${remainingPath}`;
                // const url = await api.getObjectAsSignedUrl(bucket, remainingPath);
                // return url;
            }

            // CANNOT get presigned urls to work :/
            // const url = api.getObjectAsSignedUrl(bucket, remainingPath);
            // return url;
            // return {config.aws.s3Url}/{dimensionsString}/{remainingPath}`;
        } else {
            //Bogus image
            return `/icons/tape-icon-drag@3x.png`;
        }
    }

    return functions;

}

export default Images;