import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import config from './config';
import { Amplify } from 'aws-amplify';

function initializeAmplify() {
    Amplify.configure({ // TODO: Capture response.config
        Auth: {
            mandatorySignIn: true,
            region: config.cognito.REGION,
            userPoolId: config.cognito.USER_POOL_ID,
            identityPoolId: config.cognito.IDENTITY_POOL_ID,
            userPoolWebClientId: config.cognito.APP_CLIENT_ID
        },
        Storage: {
            AWSS3: {
                region: config.aws.REGION,
                bucket: config.aws.s3JobBucket,
                identityPoolId: config.cognito.IDENTITY_POOL_ID
            }
        },
        API: {
            endpoints: [
                {
                    name: config.apiGateway.apiName,
                    endpoint: config.apiGateway.URL,
                    region: config.apiGateway.REGION
                },
            ]
        }
    });

}

console.debug("Initializing Amplify Client Lib");
initializeAmplify();

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <App />
            <div className="footer-bottom-copy"><img src="/tapeSmallIcon.png"
                                                     style={{padding: "2px"}}/> &copy; 2021 TaskTape, LLC
            </div>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
