import {Link, useParams, useRouteMatch} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAppContext} from "../libs/contextLib";
import "./ShareReceive.css";
import "../containers/Login.css";
import {useHistory} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {hardenFunkyJob} from "../libs/models";
import {onError} from "../libs/errorLib";
import Api from "../libs/api";


export default function ShareReceive(props) {

    const { user, isAuthenticated, setIsReceivingShare } = useAppContext();
    const [jobName, setJobName] = useState("");
    const [userName, setUserName] = useState("");
    const [share, setShare] = useState(null);
    const [confirmNoRegister, setConfirmNoRegister] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const shareId = useRouteMatch('/shares/:share_uuid').params.share_uuid;

    const api = Api();
    const history = useHistory();

    useEffect(() => {

        //For this page, since they might get here without going through the app.js machinery, we need
        // to check the user load?

        if (shareId && !share) {
            loadShare();
        } else {
            //Once the share is loaded, lets redirect if the user already accepted the share
            // TODO: Work out how this works if this is re-share case and so the share being loaded is
            //  not the one that was updated/generated from re-share path
            if (isAuthenticated) {
                history.push({
                    pathname: `/job/detail/${share.shareContextRefUuid}`,
                    state: { share_uuid: shareId }
                });
            }
        }

    }, [shareId, share])

    function loadShare() {
        setIsLoading(true);
        api.loadShare(shareId, !isAuthenticated)
            .then((share) => {
                setJobName(share.jobName);
                setUserName(share.userName);
                setShare(share.share);
                console.debug(`Received share detail ${JSON.stringify(share)}`);
                setIsLoading(false);
                setIsReceivingShare(true);
            })
            .catch((e) => {
                console.error(e);
                onError(e);
                setIsLoading(false);
            });
    }

    function register() {
        history.push({
            pathname: `/signup`,
            state: { share: share }
        })
    }

    function viewJob(force) {
        if (isAuthenticated || force) {
            history.push({
                pathname: `/job/detail/${share.shareContextRefUuid}`,
                state: {share_uuid: shareId, share: share}
            })
        } else {
            setConfirmNoRegister(true);
        }
    }

    function convertShare() {
        // ?
    }

    return ( !isLoading && share &&
        <>
            <style>
                {`
                   body, html {
    height: 100%;
}
body {
	background-image:url("/images/login-bg.png");
	background-repeat:no-repeat;
	background-size:cover;
	}
 nav.mb-3.navbar.navbar-expand-md.navbar-light.bg-light {
    display: none;s
}
                `}
            </style>

            <div className="Login loginPage">
                <div className="loginImage">
                    <img src='/icons/logo.png' />
                </div>
            </div>
            <div style={{textAlign: "center"}}>

                {!confirmNoRegister &&
                    <div className="share-container">
                        <div className="Welcome-to-WalkThrou" style={{
                            backgroundColor: "black",
                            width: "375px",
                            height: "164px",
                            alignContent:"center",
                            padding: "18px 0 24px"
                        }}>
                            <p>
                                <span className="welcome-to">Welcome to</span><span className="walk"> Walk</span><span className="walk through">Through</span>
                                <br />
                                <span className="by">by</span><span className="task"> Task</span><span className="task tape">Tape</span>
                            </p>
                            <span className="The-job-job-name-h">
                                The job “{jobName}” has been shared with you by “{userName}”
                            </span>
                        </div>
                        <div className="view-register-block">
                            <Button className="share-btn view-job-btn" onClick={() => viewJob(false)}>View Job</Button>
                            <Button className="share-btn register-btn" onClick={() => register()}>Register</Button>
                            <a className="learn-about-walkthro" href="https://www.tasktape.com/" target="_new">learn about walkthrough by tasktape</a>
                        </div>
                    </div>
                }

                {confirmNoRegister &&
                    <div className="share-container">
                        <div className="Welcome-to-WalkThrou" style={{
                            backgroundColor: "black",
                            width: "375px",
                            height: "164px",
                            alignContent:"center",
                            padding: "18px 0 24px"
                        }}>
                            <p>
                                {/*<span className="walk">Walk</span><span className="walk through">Through</span>*/}
                                <span>Continue without registration?</span>
                                <br />&nbsp;<br />
                            </p>
                            <Button className="share-btn view-job-btn" >Download the App for IOS TODO: Linkify/Color</Button>
                            <Button className="share-btn view-job-btn" onClick={() => viewJob(true)}>Yes - Show me the job</Button>
                        </div>
                        <div className="view-register-block">
                            <p className="post-register">
                                To see the job again please follow the original link
                                or better yet, Register now below.
                            </p>
                            <Button className="share-btn register-btn" onClick={() => register()}>Register</Button>
                            <a className="learn-about-walkthro" href="https://www.tasktape.com/" target="_new">learn about walkthrough by tasktape</a>
                        </div>
                    </div>
                }

            </div>

        </>
    );
}
