import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useHistory, useLocation } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import {useAppContext} from "../libs/contextLib";
import {Alert, Nav, Toast} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {Auth} from "aws-amplify";
import './Login.css'
import Api from "../libs/api";

export default function Signup() {
    const [fields, handleFieldChange] = useFormFields({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        confirmationCode: "",
        phoneNumber: "+1",
    });
    const history = useHistory()
    const location = useLocation();
    const [newUser, setNewUser] = useState(null);
    const { deviceUuid, setIsReceivingShare, setAcceptShareFailed, setConfirmShareFailed, userHasAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const api = Api();
    if (location.state && location.state.share)  console.log(`SIGNUP GOT SHARE FROM STATE: ${JSON.stringify(location.state.share)}`);

    function validateForm() {
        return (
            fields.firstName.length > 0 &&
            fields.lastName.length > 0 &&
            fields.email.length > 0 &&
            fields.password.length > 0 &&
            fields.phoneNumber.length > 0 &&
            fields.password === fields.confirmPassword
        );
    }

    function validateConfirmationForm() {
        return fields.confirmationCode.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            //Create user in Cognito
            const newUser = await Auth.signUp({
                username: fields.email,
                password: fields.password,
                attributes: {
                    email: fields.email,
                    phone_number: fields.phoneNumber,
                    given_name: fields.firstName,
                    family_name: fields.lastName,
                    "custom:source": "desktop"
                }
            });

            //Update user in Tasktape

            //TODO default settings?
            // const settings = {
            //
            // }

            setIsLoading(false);
            setNewUser(newUser);
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    async function handleConfirmationSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            await Auth.confirmSignUp(fields.email, fields.confirmationCode);
            await Auth.signIn(fields.email, fields.password);

            if (location.state && location.state.share) {
                await api.confirmShare(location.state.share_uuid, deviceUuid)
                    .then((share) => {
                    })
                    .catch((err) => {
                        console.log(`Problem receiving share ${err}`);
                    });

                //We came from the share or job detail page for a user who wasn't signed in
                //If they are now registering, then this is a "conversion" from the viral path
                await api.convertShare(location.state.share, deviceUuid);

                //Lets see if we need all 3 here wth
                setIsReceivingShare(false);
                setAcceptShareFailed(true);
                setConfirmShareFailed(true);

            }

            userHasAuthenticated(true);
            history.push("/");
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    function renderConfirmationForm() {
        return (
            <Form onSubmit={handleConfirmationSubmit}>
                <Form.Group controlId="confirmationCode" size="lg">
                    <Form.Label>Confirmation Code</Form.Label>
                    <Form.Control
                        autoFocus
                        type="tel"
                        onChange={handleFieldChange}
                        value={fields.confirmationCode}
                    />
                    <Form.Text muted>Please check your email for the code.</Form.Text>
                </Form.Group>
                <LoaderButton
                    block
                    size="lg"
                    type="submit"
                    isLoading={isLoading}
                    disabled={!validateConfirmationForm()}
                >
                    Verify
                </LoaderButton>
            </Form>
        );
    }

    function renderForm() {
        return (
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="firstName" size="lg">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                        autoFocus
						placeholder="first name"
                        type="string"
                        value={fields.firstName}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <Form.Group controlId="lastName" size="lg">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                        autoFocus
						placeholder="last name"
                        type="string"
                        value={fields.lastName}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <Form.Group controlId="email" size="lg">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        autoFocus
						placeholder="email"
                        type="email"
                        value={fields.email}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <Form.Group controlId="phoneNumber" size="lg">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                        type="phone"
						placeholder="phone"
                        value={fields.phoneNumber}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <Form.Group controlId="password" size="lg">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
						placeholder="password"
                        value={fields.password}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <Form.Group controlId="confirmPassword" size="lg">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                        type="password"
						placeholder="confirm password"
                        onChange={handleFieldChange}
                        value={fields.confirmPassword}
                    />
                </Form.Group>
                <LoaderButton
                    block
                    size="lg"
                    type="submit"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                >
                    Sign up
                </LoaderButton>
				<Form.Text>
                        <span className="create-account">
                            Already have an account? <LinkContainer to="/login">
                                 <Nav.Link>Login</Nav.Link>
                            </LinkContainer>
                        </span>
                        
                    </Form.Text>
            </Form>
        );
    }

    return (
	<>
             <style>
                {`
                   body, html {
    height: 100%;
}
body {
	background-image:url("images/login-bg.png");
	background-repeat:no-repeat;
	background-size:cover;
	}
 nav.mb-3.navbar.navbar-expand-md.navbar-light.bg-light {
    display: none;
}
                `}
            </style>

        <div className="Signup loginPage signupPage">
		<div className="loginImage signImage">
			   <img src='/icons/logo.png' />
			  </div>
               <div className="loginForm signupForm">
			   <h3>Create Account</h3>
            {newUser === null ? renderForm() : renderConfirmationForm()}
        </div>
        </div>
		</>
    );
}