import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import JobsList from "./containers/tasktape/JobsList";
import JobDetail from "./containers/tasktape/JobDetail";
import ForgotPassword from "./containers/ForgotPassword";
import User from "./containers/tasktape/User";
import {AppContext, useAppContext} from "./libs/contextLib";
import ShareReceive from "./components/ShareReceive";

export default function Routes() {

    const { isAuthenticated } = useAppContext();

    return (
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route path="/job/detail/:job_uuid/:area_uuid?/:view_uuid?/:tape_uuid?">
                <JobDetail />
            </Route>
            <Route exact path="/shares/:share_uuid">
                <ShareReceive />
            </Route>
            <Route exact path="/user">
                <User />
            </Route>
            <Route exact path="/jobs/list">
                { isAuthenticated && <JobsList /> }
            </Route>
            <Route exact path="/login">
                <Login />
            </Route>
            <Route exact path="/signup">
                <Signup />
            </Route>
            <Route exact path="/forgot-password">
                <ForgotPassword />
            </Route>
            <Route>
                <NotFound />
            </Route>
        </Switch>
    );
}