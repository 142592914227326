import React, {useContext, useEffect, useReducer, useState} from "react";
import "./Feed.css";
import _ from "lodash";
import {ButtonGroup, ButtonToolbar, Dropdown, DropdownButton, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Note from "./Note";
import Authorization from "../../libs/authorization";
import Button from "react-bootstrap/Button";
import {NEW_NOTE, SELECT_TAPE} from "../../containers/tasktape/NoteInterfaceActions";
import {useAppContext} from "../../libs/contextLib";
import {FeedContext} from "../../libs/feedContextLib";


/**
 *
 * Feed hosts the "Annotations" interface, displaying a list of all the notes related
 * to a particular tape. The note types are handled individually, via
 *
 * ImageNote: Images can be individually deleted. Last image deletes entire note.
 * TextNote: Text can be edited until replied to
 * VideoNote: Video can be deleted which deletes entire note
 * AudioNote: Audio can be deleted which deletes entire note
 *
 * All types can be replied to.
 * but they all share a common reply interface via NoteReply. NoteReplyContent handles the
 * input box.
 */
export default function Feed(props) {

    const { feed, dispatch } = useContext(FeedContext);
    const { user } = useAppContext();
    const [noteList, setNoteList] = useState([]);

    const noteAuth = Authorization().notes;

    useEffect(() => { //IF no args then run only once per render I think?
        dispatch({ type: SELECT_TAPE, payload: props.activeTape, user: user });
    }, [])

    useEffect(() => {

        let noteList = [];
        if (feed && feed.activeTape && feed.activeTape.notes) {
            noteList.push(feed.activeTape.notes.IMAGES, feed.activeTape.notes.TEXT, feed.activeTape.notes.VIDEO, feed.activeTape.notes.AUDIO);
        }

        noteList = _.compact(noteList);
        noteList = _.flatten(_.orderBy(noteList, ['createdOn'], ['desc']));
        setNoteList(noteList);
    }, [feed, feed.activeTape, feed.activeNote, feed.activeReply])


    /**
     * https://solipsar.atlassian.net/wiki/spaces/TASK/pages/27918337/Reply+Chat+Management+Details
     * The originating "note" (text/video/audio/image) can ONLY be deleted or modified until the first reply has been
     * added, at which time it is "locked" for changes. This prevents context from being lost.

     * Replies can be edited or deleted ONLY by the user who created them, EXCEPT the Job Owner (uuid of
     * the job.createdUser) who can also delete, but not edit.

     * Replies can only be edited until there is a subsequent reply at which time they are "locked" for changes,
     * similar to Note rule. However, deletion rules from above still apply.
     *
     * We conditionally display the icons/handlers for reply interactions here in Feed, except for
     * the users ability to edit or delete replies which are handled in NoteReply.
     *
     */
    return (
        <>
        {
            noteAuth.canCreate() &&

            <ButtonGroup className="add-note-list-buttongroup">
                <DropdownButton as={ButtonToolbar} title="+" id="bg-add-notes-nested-dropdown" autoclose="true">
                    {/*<ButtonToolbar aria-label="Toolbar with button groups">*/}
                        <ButtonGroup className="add-note-list-buttongroup" aria-label="Notes" vertical>
                            <Button onClick={() => dispatch({ type: NEW_NOTE, noteType: "TEXT" })}><FontAwesomeIcon icon="comment-alt" color="#1a67d3" inverse transform="shrink-6" size="2x" /></Button>
                            <Button onClick={() => dispatch({ type: NEW_NOTE, noteType: "IMAGES" })}><FontAwesomeIcon icon="camera" color="#1a67d3" inverse transform="shrink-6" size="2x" /></Button>
                            {/*<Button><FontAwesomeIcon icon="video" color="#1a67d3" inverse transform="shrink-6" size="2x" /></Button>*/}
                            {/*<Button><FontAwesomeIcon icon="volume-up" color="#1a67d3" inverse transform="shrink-6" size="2x" /></Button>*/}
                        </ButtonGroup>
                    {/*</ButtonToolbar>*/}
                </DropdownButton>

            </ButtonGroup>
        }
        <div style={feed.isSaving ? {pointerEvents: "none", opacity: "0.4"} : {}}> {/* TODO: Center */}
            <Spinner animation="border" className={ !feed.isSaving && "invisible"} />

        {noteList.map((note) =>
            <Note key={note.noteUuid} note={note} />
        )}
        </div>
        </>
    );
}