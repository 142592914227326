import React, {useContext, useState} from "react";
import "./Feed.css";
import {Col, Row} from "react-bootstrap";
import NoteHeader from "./NoteHeader";
import ImageNoteContent from "./ImageNoteContent";
import {FeedContext} from "../../libs/feedContextLib";
import {CANCEL_EDIT_NOTE, noteActions, PUT_NOTE, SELECT_NOTE} from "../../containers/tasktape/NoteInterfaceActions";
import {onError} from "../../libs/errorLib";
import _ from "lodash";
import Form from "react-bootstrap/Form";
import LoaderButton from "../LoaderButton";
import Linkify from "react-linkify";
import Note from "./Note";
import {constants} from "../../libs/models";
import MultipleImageUploadComponent from "../MultiImageUpload";
import {v4 as uuidv4} from "uuid";
import * as models from "../../libs/models";
import {useAppContext} from "../../libs/contextLib";
import Api from "../../libs/api";
import Image from "../../libs/image";
import {newImage} from "../../libs/models";
import {useJobContext} from "../../libs/jobContextLib";
import config from "../../config";

export default function ImageNote(props) {

  const {isAuthenticated, user} = useAppContext();

  const { feed, dispatch } = useContext(FeedContext);
  const { deepLinkIds } = useJobContext();
  const [images, setImages] = useState([]);
  const api = Api();
  const imageLib = Image();

  function noteValid() {
    return images.length > 0;
  }

  async function saveNote(e) {
    e.preventDefault();

    dispatch({ type: PUT_NOTE, payload: props.note });

    try {
      //Do uploads
      await Promise.all(_.map(images, (image) => pushNoteImageToS3(image.src)));

      const updatedNote = await noteActions.putNote(feed.activeNote);
      dispatch({type: SELECT_NOTE, payload: updatedNote});

    } catch (e) {
      console.error(e);
      onError("Unable to save note to server");
      dispatch({type: CANCEL_EDIT_NOTE});
    }

  }

  async function pushNoteImageToS3(imageData) {
    const newMediaUuid = uuidv4();
    const key = imageLib.getNoteImageStorageKey(user, deepLinkIds.jobUuid, feed.activeTape.locationUuid, feed.activeTape.tapeUuid, newMediaUuid);

    const contentUrl = `https://s3.amazonaws.com/${config.aws.s3JobBucket}/${key}`;

    props.note.content.push(newImage(user, contentUrl, contentUrl, newMediaUuid));
    console.log(`Writing ${imageData} to S3 at : ${key} `);

    const imageBlob = await fetch(imageData)
      .then(res => res.blob()) // Gets the response and returns it as a blob
      .then(blob => {
        return blob;
      });

    return api.pushObjectToS3(key, imageBlob);

  }

  function cancelSaveNote(e) {
    e.preventDefault();
    dispatch({ type: CANCEL_EDIT_NOTE });
    //TODO: Look at moving this to NoteInterfaceActions?
    _.remove(feed.activeTape.notes["IMAGES"], (iterNote) => iterNote.noteUuid === feed.activeNote.noteUuid)
  }

  return (
    <>
      <Row>
        <Col className="modal-title-main">
          <div className="modal-inner">
            <NoteHeader note={props.note} replyNote={props.replyNote}/>
          </div>
        </Col>
      </Row>

      {!feed.addingNote &&
        <ImageNoteContent note={props.note} edit={feed.editingNote} />
      }
      {props.note.noteUuid === feed.activeNote?.noteUuid
        && feed.activeNote?.noteUuid === constants.DEFAULT_UNSAVED_UUID
        && feed.addingNote &&
      <Form>
        <MultipleImageUploadComponent setImages={setImages} />
        <LoaderButton
          size="md"
          type="save"
          variant="primary"
          isLoading={feed.saving}
          disabled={!noteValid()}
          onClick={(e) => saveNote(e)}
        >
          Save
        </LoaderButton>

        <LoaderButton
          size="md"
          type="save"
          variant="light"
          onClick={(e) => cancelSaveNote(e)}
        >
          Cancel
        </LoaderButton>

      </Form>
      }
    </>
  );
}
/**
 [
 {
                    "content": [
                      {
                        "contentUrl": "https://s3.amazonaws.com/023464524600-tasktape-dev/users/c1db9926-4a0a-4089-8dbf-ea667eae5434/jobs/8A675488-5A2B-472A-9564-0E395145EB18/locations/FE57C009-2C6E-4F6E-8829-235C375AA4ED/tapes/B96A176F-0122-4BF7-BBE6-565AA9F4D95E/image_note_865E6C14-3014-415E-B0E1-C3A1B7B3BB17_000.jpg",
                        "width": 0,
                        "description": " ",
                        "mediaUuid": "68B73C5B-B1F5-43ED-B8B9-7083A6EFB9D7",
                        "originalUrl": "https://s3.amazonaws.com/023464524600-tasktape-dev/users/c1db9926-4a0a-4089-8dbf-ea667eae5434/jobs/8A675488-5A2B-472A-9564-0E395145EB18/locations/FE57C009-2C6E-4F6E-8829-235C375AA4ED/tapes/B96A176F-0122-4BF7-BBE6-565AA9F4D95E/image_note_865E6C14-3014-415E-B0E1-C3A1B7B3BB17_000.jpg",
                        "createdOn": 1619246279472,
                        "createdUser": "c1db9926-4a0a-4089-8dbf-ea667eae5434",
                        "height": 0
                      },
                      {
                        "contentUrl": "https://s3.amazonaws.com/023464524600-tasktape-dev/users/c1db9926-4a0a-4089-8dbf-ea667eae5434/jobs/8A675488-5A2B-472A-9564-0E395145EB18/locations/FE57C009-2C6E-4F6E-8829-235C375AA4ED/tapes/B96A176F-0122-4BF7-BBE6-565AA9F4D95E/image_note_F79772F1-44E5-46CB-8049-AE654F0DBE5D_001.jpg",
                        "width": 0,
                        "description": " ",
                        "mediaUuid": "1A57E678-7814-4A82-ADF7-AF7461EC2046",
                        "originalUrl": "https://s3.amazonaws.com/023464524600-tasktape-dev/users/c1db9926-4a0a-4089-8dbf-ea667eae5434/jobs/8A675488-5A2B-472A-9564-0E395145EB18/locations/FE57C009-2C6E-4F6E-8829-235C375AA4ED/tapes/B96A176F-0122-4BF7-BBE6-565AA9F4D95E/image_note_F79772F1-44E5-46CB-8049-AE654F0DBE5D_001.jpg",
                        "createdOn": 1619246279510,
                        "createdUser": "c1db9926-4a0a-4089-8dbf-ea667eae5434",
                        "height": 0
                      },
                      {
                        "contentUrl": "https://s3.amazonaws.com/023464524600-tasktape-dev/users/c1db9926-4a0a-4089-8dbf-ea667eae5434/jobs/8A675488-5A2B-472A-9564-0E395145EB18/locations/FE57C009-2C6E-4F6E-8829-235C375AA4ED/tapes/B96A176F-0122-4BF7-BBE6-565AA9F4D95E/image_note_21E90AE0-C663-4E21-B01C-19DDC90FE73E_002.jpg",
                        "width": 0,
                        "description": " ",
                        "mediaUuid": "70AD09F4-3740-4CB1-9C78-12F94B13CA5A",
                        "originalUrl": "https://s3.amazonaws.com/023464524600-tasktape-dev/users/c1db9926-4a0a-4089-8dbf-ea667eae5434/jobs/8A675488-5A2B-472A-9564-0E395145EB18/locations/FE57C009-2C6E-4F6E-8829-235C375AA4ED/tapes/B96A176F-0122-4BF7-BBE6-565AA9F4D95E/image_note_21E90AE0-C663-4E21-B01C-19DDC90FE73E_002.jpg",
                        "createdOn": 1619246279548,
                        "createdUser": "c1db9926-4a0a-4089-8dbf-ea667eae5434",
                        "height": 0
                      },
                      {
                        "contentUrl": "https://s3.amazonaws.com/023464524600-tasktape-dev/users/c1db9926-4a0a-4089-8dbf-ea667eae5434/jobs/8A675488-5A2B-472A-9564-0E395145EB18/locations/FE57C009-2C6E-4F6E-8829-235C375AA4ED/tapes/B96A176F-0122-4BF7-BBE6-565AA9F4D95E/image_note_5D4B1BB4-8AA1-411F-BA65-3CEA2FABC5DB_003.jpg",
                        "width": 0,
                        "description": " ",
                        "mediaUuid": "789FA308-2087-4099-8E67-1B94DAEE83DA",
                        "originalUrl": "https://s3.amazonaws.com/023464524600-tasktape-dev/users/c1db9926-4a0a-4089-8dbf-ea667eae5434/jobs/8A675488-5A2B-472A-9564-0E395145EB18/locations/FE57C009-2C6E-4F6E-8829-235C375AA4ED/tapes/B96A176F-0122-4BF7-BBE6-565AA9F4D95E/image_note_5D4B1BB4-8AA1-411F-BA65-3CEA2FABC5DB_003.jpg",
                        "createdOn": 1619246279586,
                        "createdUser": "c1db9926-4a0a-4089-8dbf-ea667eae5434",
                        "height": 0
                      }
                    ],
                    "tapeUuid": "B96A176F-0122-4BF7-BBE6-565AA9F4D95E",
                    "version": 0,
                    "createdUserLastName": "Lover",
                    "createdOn": 1619246377038,
                    "createdUserFirstName": "John ",
                    "shareable": true,
                    "description": " ",
                    "createdUser": "c1db9926-4a0a-4089-8dbf-ea667eae5434",
                    "noteUuid": "04AFA03E-E2A0-4099-AADF-36D811C7E6A5",
                    "tags": [],
                    "type": "IMAGES",
                    "replies": [
                      {
                        "content": "Because I forgot ",
                        "tapeUuid": "B96A176F-0122-4BF7-BBE6-565AA9F4D95E",
                        "version": 1,
                        "readBy": [
                          "ba41452a-f770-4057-ae40-6f3117d682a1"
                        ],
                        "createdUserFirstName": "J",
                        "jobOwnerUuid": "ba41452a-f770-4057-ae40-6f3117d682a1",
                        "createdUser": "ba41452a-f770-4057-ae40-6f3117d682a1",
                        "noteUuid": "04AFA03E-E2A0-4099-AADF-36D811C7E6A5",
                        "deleted": false,
                        "replyUuid": "012A8CF0-4F98-4B14-8BC1-69CC2D451D53",
                        "createdUserLastName": "H",
                        "createdOn": 1619384604389,
                        "edited": false,
                        "jobUuid": "8A675488-5A2B-472A-9564-0E395145EB18",
                        "locationUuid": "FE57C009-2C6E-4F6E-8829-235C375AA4ED"
                      }
                    ]
                  }
 ]
 **/