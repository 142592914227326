import config from '../config';

import {onError} from "./errorLib";
import _ from "lodash";

import {Auth} from "@aws-amplify/auth/lib";
import {API} from "@aws-amplify/api/lib";
import {S3Client, PutObjectCommand, GetObjectCommand} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { Hub, Logger } from 'aws-amplify';
import {v4 as uuidv4} from "uuid";
import {constants} from "./models";


export default function Api() {

    const DEBUG = process.env.REACT_APP_STAGE === 'dev';

    // const history = useHistory();

    //🚨 don't embed api keys in real life!!
    const api_key = "B7lyNX3lNp8jm53qXK6geajMtKnJTEas4TQ2q8MS";
    const api_name = "TasktapeAPI"; //Must match value in amplifyconfig json

    let credentialsCache = undefined;

    function getCurrentUserCredentials() {
        try {
            if (credentialsCache === undefined) {
                credentialsCache = Auth.currentUserCredentials()
                    .catch((err) => {
                            console.log(err);
                            console.log("User credentials expired. should redirect to login");
                        /**
                         * TODO: Should have a general exception here that says "redirect to login" to callers.
                         * The problem is this api cannot have useHistory hook since it is used on contexts where there
                         * are no hook/react component. Shit encapsulation here.
                         */
                        // history.push(`/login`)
                        });

            }
            return credentialsCache;
        } catch (error) {
            console.log("User credentials expired. Redirecting to login");
            // history.push(`/login`)
        }
    }


    const logger = new Logger('My-Logger');

    const listener = (data) => {
        switch (data.payload.event) {
            case 'signIn':
                logger.info('AMPLIFY_AUTH_EVENT --------- user signed in');
                break;
            case 'signUp':
                logger.info('AMPLIFY_AUTH_EVENT --------- user signed up');
                break;
            case 'signOut':
                logger.info('AMPLIFY_AUTH_EVENT --------- user signed out');
                break;
            case 'signIn_failure':
                logger.error('AMPLIFY_AUTH_EVENT --------- user sign in failed');
                break;
            case 'tokenRefresh':
                logger.info('AMPLIFY_AUTH_EVENT --------- token refresh succeeded');
                break;
            case 'tokenRefresh_failure':
                logger.error('AMPLIFY_AUTH_EVENT --------- token refresh failed');
                break;
            case 'configured':
                logger.info('AMPLIFY_AUTH_EVENT --------- the Auth module is configured');
        }
    }

    Hub.listen('auth', listener);

    async function getXhrAuthenticationToken() {
        const user = await Auth.currentSession();
        if (DEBUG) {
            console.log(`API.getToken().user = ${JSON.stringify(user)}`);
        }
        return `Bearer ${user.getIdToken().jwtToken}`;
    }

    async function getCognitoIDToken() {
        const user = await Auth.currentSession();
        const token = user.getAccessToken();
        if (DEBUG) {
            console.log(`Cognito userAccessToken = ${JSON.stringify(token)}`);
        }
        return token;
    }

    async function standardHeaders() {
        return {
            "x-api-key": api_key,
            "authentication": await getXhrAuthenticationToken()
        }
    }

    const functions = {};

    functions.getCognitoIDToken = getCognitoIDToken;
    functions.getToken = getXhrAuthenticationToken;

    let s3Client = undefined;

    function getS3Client() {
        if (s3Client === undefined) {
            const credentials = getCurrentUserCredentials();

            try {
                s3Client = new S3Client({
                    region: config.aws.REGION,
                    credentials: credentials,
                    signatureVersion: 'v4'
                });
            } catch (error) {
                console.log("S3 Client Build Failure!");
                console.error(error);
                throw Error(error);
            }
        }

        return s3Client;
    }

    functions.pushObjectToS3 = async function(key, contents) {

        const params = {
            Bucket: config.aws.s3JobBucket,
            Key: key,
            Body: contents,
            ContentType: 'image/jpeg',
            ACL:'public-read'
        }

        try {
            const s3Client = getS3Client();
            return s3Client.send(new PutObjectCommand(params));
        } catch (error) {
            // error handling.

            console.log("Push Image Failure!");
            console.error(error);
            throw Error(error);
        } finally {
            // finally.
        }

    }

    functions.getObjectAsSignedUrl = async function(bucket, key, expireSeconds) {



        // function getS3() {
        //     //Required because of affixing in the dynamo_utils.js
        //     AWS.config.update({
        //         region: config.aws.REGION,
        //         endpoint: "https://s3.amazonaws.com",
        //         signatureVersion: 'v4'
        //     });
        //
        //
        //     if (config.ENV !== "dev" && config.ENV !== "prod") {
        //         // let credentials = new AWS.SharedIniFileCredentials({profile: 'default'});
        //         AWS.config.credentials = getCurrentUserCredentials();
        //     }
        //
        //     return new AWS.S3();
        // }
        //
        // //V2 version that is bigger but works?
        // return getS3().getSignedUrl('getObject', {
        //     Bucket: bucket,
        //     Key: key,
        //     Expires: expireSeconds
        // });

        // V3 with "middleware" fuckstick version that throws up stupid shit errors all the time
        try {

            const params = {
                Bucket: bucket,
                Key: key
            }

            const client = getS3Client();
            const command = new GetObjectCommand(params);
            client.send();
            const url = await getSignedUrl(client, command, { expiresIn: expireSeconds });
            return url;

        } catch (error) {
            //Should assume not logged in and direct user to login?
            console.log("Unable to create presigned url");
            console.error(error);

            throw Error(error);
        }

    }

    functions.awsSvcCredentials = async function() {
        // AWS.CognitoIdentityCredentials({
        //
        // })
        // AWS.config.update({
        //     region: config.aws.REGION,
        //     credentials: new AWS.CognitoIdentityCredentials({
        //         IdentityPoolId: config.cognito.IDENTITY_POOL_ID
        //     })
        // });

        // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        //     IdentityPoolId: 'MY_IDENTITY_POOL',
        //     IdentityId: data.IdentityId,
        //     Logins: {
        //         'cognito-identity.amazonaws.com': data.Token
        //     }
        // });

        const credentials = await Auth.currentCredentials();
        return Auth.essentialCredentials(credentials);
    }

    async function execApiMethod(method) {
        try {
            if (DEBUG) {
                console.log(`API.execApiMethod requested with = ${method}`);
            }

            return method();
        }
        catch(e) {
            console.log("API Invocation failure!");
            console.error(e);
            onError(e);
        }

    }

    functions.putJob = async function(job, jobUuid) {
        return execApiMethod(async() => {

            return await API.put(api_name, `/jobs/${jobUuid}`, {
                headers: await standardHeaders(),
                body: job
            });

        });

    }

    functions.getJobs = async function() {
        return execApiMethod(async() => {

            return await API.get(api_name, "/jobs", {
                headers: await standardHeaders()
            });

        });
    }

    functions.getJob = async function(jobUuid, anonymousLoad) {
        return execApiMethod(async() => {

            if (anonymousLoad) {
                return await API.get(api_name, `/jobs/${jobUuid}`, {
                    headers: {
                        "x-api-key": api_key
                    }
                });

            } else {
                return await API.get(api_name, `/jobs/${jobUuid}`, {
                    headers: await standardHeaders()
                });
            }

        });
    }

    functions.deleteJob = async function(jobUuid) {
        return execApiMethod(async() => {

            return await API.del(api_name, `/jobs/${jobUuid}`, {
                headers: await standardHeaders()
            });

        });
    }

    functions.loadShare = async function(shareUuid, anonymousLoad) {
        return execApiMethod(async() => {

            if (anonymousLoad) {
                return await API.get(api_name, `/shares/${shareUuid}`, {
                    headers: {
                        "x-api-key": api_key
                    }
                });
            } else {
                return await API.get(api_name, `/shares/${shareUuid}`, {
                    headers: await standardHeaders()
                });
            }

        });
    }

    functions.confirmShare = async function(shareUuid, deviceUuid) {
        return execApiMethod(async() => {

            return await API.put(api_name, `/shares/${shareUuid}/confirm?deviceUuid=${deviceUuid}&usingBrowser=true&userJoined=true`, {
                headers: await standardHeaders()
            });

        });
    }

    functions.convertShare = async function(shareData, deviceUuid) {
        return execApiMethod(async() => {

            return await API.put(api_name, `/shares/${shareData.userJobShareUuid}/convert?deviceUuid=${deviceUuid}`, {
                headers: await standardHeaders(),
                body: shareData
            });

        });
    }

    functions.shareJob = async function(newShareUuid, shareData) {
        return execApiMethod(async() => {

            return await API.put(api_name, `/shares/${newShareUuid}`, {
                headers: await standardHeaders(),
                body: shareData
            });

        });
    }

    functions.emailShare = async function(newShareUuid, emailShare) {
        return execApiMethod(async() => {

            return await API.put(api_name, `/shares/${newShareUuid}/send_email`, {
                headers: await standardHeaders(),
                body: emailShare
            });

        });
    }

    functions.getUser = async function() {
        return execApiMethod(async() => {

            const user = await API.get(api_name, "/user", {
                headers: await standardHeaders(),
            });

            return user;

        });
    }

    functions.getUserNotifications = async function(userData) {
        return execApiMethod(async() => {

            const notifications = await API.get(api_name, "/notifications", {
                headers: await standardHeaders(),
            });

            return notifications;

        });
    }

    functions.putUser = async function(userData) {
        return execApiMethod(async() => {

            const user = await API.put(api_name, "/user", {
                headers: await standardHeaders(),
                body: userData
            });

            return user;

        });
    }

    functions.putUserProfile = async function(user, profileData) {

        let profileBody = {
            "userUuid": user.userUuid,
            "profile": profileData
        };

        return execApiMethod(async() => {

            const user = await API.put(api_name, "/user/profile", {
                headers: await standardHeaders(),
                body: profileBody
            });

            return user;

        });
    }

    functions.putUserSettings = async function(user, settingsData) {

        let settingsBody = {
            "userUuid": user.userUuid,
            "settings": settingsData
        };

        return execApiMethod(async() => {

            const user = await API.put(api_name, "/user/settings", {
                headers: await standardHeaders(),
                body: settingsBody
            });

            return user;

        });
    }

    functions.putTape = async function(tapeData) {

        const updateableTapeData = _.clone(tapeData);
        let notes = updateableTapeData.notes; //Store off notes to reattach later
        if (notes) {
            delete updateableTapeData.notes; //Comes from the stitched job-detail payload this way
        }
        return execApiMethod(async() => {

            const tape = await API.put(api_name, `/locations/${updateableTapeData.locationUuid}/tapes/${updateableTapeData.tapeUuid}`, {
                headers: await standardHeaders(),
                body: updateableTapeData
            });

            tape.notes = notes; //Retaining data structure...feels wrong way to do thsi though.
            return tape;

        });
    }

    functions.putNote = async function(noteData, locationUuid) {
            ///locations/{location_uuid}/tapes/{tape_uuid}/notes/{note_uuid}
        if (noteData.noteUuid === constants.DEFAULT_UNSAVED_UUID) { //Create new note
            noteData.noteUuid = uuidv4();
        }
        return execApiMethod(async() => {

            const note = await API.put(api_name, `/locations/${locationUuid}/tapes/${noteData.tapeUuid}/notes/${noteData.noteUuid}`, {
                headers: await standardHeaders(),
                body: noteData
            });

            return note;

        });
    }

    functions.deleteNote = async function(noteData, locationUuid) {
            ///locations/{location_uuid}/tapes/{tape_uuid}/notes/{note_uuid}
        return execApiMethod(async() => {

            const note = await API.del(api_name, `/locations/${locationUuid}/tapes/${noteData.tapeUuid}/notes/${noteData.noteUuid}`, {
                headers: await standardHeaders()
            });

            return note;

        });
    }


    functions.putReply = async function(replyData) {

        return execApiMethod(async() => {
            /** The backend assumes no reply uuid means insert vs edit. However, we needed
             * a replyUuid for the react components to correctly render so we stubbed one in using
             * this constant `UNSAVED_REPLY` **/
            /**
             * Unable to get PUT /reply/{uuuid} to work without a uuid on the backend so punting for now
             * This means we should manually manage edited: true I guess ... blargh.
             */
            if (replyData.replyUuid === constants.DEFAULT_UNSAVED_REPLY_UUID) { //Create new reply
                // delete replyData['replyUuid'];
                replyData.replyUuid = uuidv4();
            }
            const reply = await API.put(api_name, `/replies/${replyData.replyUuid || ''}`, {
                headers: await standardHeaders(),
                body: replyData
            });

            return reply;

        });
    }

    functions.deleteReply = async function(replyData) {

        return execApiMethod(async () => {

            await API.del(api_name, `/replies/${replyData.replyUuid}`, {
                headers: await standardHeaders(),
                body: replyData
            });

        });
    }

    functions.readReply = async function(replyUuid) {

        return execApiMethod(async() => {

            const reply = await API.put(api_name, `/replies/${replyUuid}/read`, {
                headers: await standardHeaders()
            });

            return reply;

        });
    }



    return functions;
}

Object.freeze(Api);

