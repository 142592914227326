

import React, {useEffect} from "react";
import ImageUploader from "react-images-upload"; // https://github.com/jakehartnell/react-images-upload#readme
import {useHistory} from "react-router-dom";
import {useAppContext} from "../libs/contextLib";
import {Container} from "@material-ui/core";
import {Col, Row} from "react-bootstrap";
import _ from "lodash";


export default function CreateJob(props) {

  const {isAuthenticated, user} = useAppContext();
  const history = useHistory();

  function makeImage() {
    return {
      src: "",
      fileUrl: "",
      croppedImageUrl: ""
    }
  }

  useEffect(() => {
    if (!isAuthenticated) {
      history.push(`/login`)
    }

  }, [props.note])

  const onDrop = pictures => {
    props.setImages([]);
    const newImges = [];

    _.forEach(pictures, (file) => {
      if (file) {

        const fileReader = new FileReader();
        const image = makeImage();

        fileReader.onloadend = () => {

          image.src = fileReader.result;
          newImges.push(image);

          props.setImages(newImges);
        }

        fileReader.readAsDataURL(file);

        console.log(`Image dropped ${JSON.stringify(file)}`)
      }

    })

  };

  useEffect(() => { //IF no args then run only once per render I think?
    if (!isAuthenticated) { // TODO: Figure out why this is soemtimes true when we just logged in!} || user.userUuid === undefined) {
      //TODO: Isn't/can't this be handled by app-level js? We can't have to repeat this in every file
      history.push(`/login`)
    }

  }, [])


  return (
    <Container>
      <Row>
        <Col className="upper-image-main">
          <>
            <ImageUploader
              {...props}
              withPreview={true}
              // withIcon={true}
              onChange={onDrop}
              buttonText={"Add Note Images"}
              label={"Max 5mb, jpg, png"}
              // buttonStyles={"submit"}
              imgExtension={[".jpg", ".png", ".jpeg"]}
              maxFileSize={5242880}
              singleImage={false}
            />

          </>
        </Col>
      </Row>
    </Container>

  )
}