import React from "react";
import "./Feed.css";
import {Col, Container, Row} from "react-bootstrap";
import NoteReplies from "./NoteReplies";
import ReactPlayer from 'react-player/file';
import NoteHeader from "./NoteHeader";

export default function VideoNote(props) {

    return (
        <>
            <Row>
                <Col><NoteHeader note={props.note} replyNote={props.replyNote}/></Col>
            </Row>
            <Row>
                <Col><hr /></Col>
            </Row>
            <Row>
                <Col className="popvideo-sect">
                    <div>
                        {props.note && props.note.content && props.note.content[0].description}
                    </div>
                    <ReactPlayer
                        url={props.note && props.note.content && props.note.content.length > 0 && props.note.content[0].contentUrl}
                        controls={true}
                        config={{
                            file: {
                                forceVideo: true
                            }
                        }}
                    />
                </Col>
            </Row>
        </>
    );
}

/**
 {
                                        "content": [
                                            {
                                                "contentUrl": "https://s3.amazonaws.com/023464524600-tasktape-dev/users/6df7f44e-8ef7-4217-8d0f-1216900f00ce/jobs/E49194DB-760C-4A95-9939-AF43B2DD2368/locations/BD8F9C54-0143-434C-A633-BD447B046B2F/tapes/B1428813-CF9E-40E5-AD63-6F365FF93E99/64219437202__3800D17B-08AE-4FEC-A9F4-A8A22A708E80.MOV",
                                                "width": 0,
                                                "description": "Landscape position video",
                                                "mediaUuid": "33E54317-90A7-4220-99AA-EFC9C9D8D7E0",
                                                "originalUrl": "https://s3.amazonaws.com/023464524600-tasktape-dev/users/6df7f44e-8ef7-4217-8d0f-1216900f00ce/jobs/E49194DB-760C-4A95-9939-AF43B2DD2368/locations/BD8F9C54-0143-434C-A633-BD447B046B2F/tapes/B1428813-CF9E-40E5-AD63-6F365FF93E99/64219437202__3800D17B-08AE-4FEC-A9F4-A8A22A708E80.MOV",
                                                "createdOn": 1620501591771,
                                                "createdUser": "6df7f44e-8ef7-4217-8d0f-1216900f00ce",
                                                "height": 0
                                            }
                                        ],
                                        "tapeUuid": "B1428813-CF9E-40E5-AD63-6F365FF93E99",
                                        "version": 0,
                                        "createdUserLastName": "Mathias",
                                        "createdOn": 1620501676407,
                                        "createdUserFirstName": "Chris+6",
                                        "shareable": true,
                                        "description": "video_note_32E10DEE-FB9C-4B29-A0E3-D60B2E53C8DC.MOV",
                                        "createdUser": "6df7f44e-8ef7-4217-8d0f-1216900f00ce",
                                        "noteUuid": "32E10DEE-FB9C-4B29-A0E3-D60B2E53C8DC",
                                        "tags": [],
                                        "type": "VIDEO",
                                        "replies": []
                                    },
 {
                                        "content": [
                                            {
                                                "contentUrl": "https://s3.amazonaws.com/023464524600-tasktape-dev/users/6df7f44e-8ef7-4217-8d0f-1216900f00ce/jobs/E49194DB-760C-4A95-9939-AF43B2DD2368/locations/BD8F9C54-0143-434C-A633-BD447B046B2F/tapes/B1428813-CF9E-40E5-AD63-6F365FF93E99/64219439862__941DA4D5-D760-459E-88B6-BE0455E27384.MOV",
                                                "width": 0,
                                                "description": "Portrait position video",
                                                "mediaUuid": "F1B8A3EA-DAF5-4857-BFD7-0BD49EC045B8",
                                                "originalUrl": "https://s3.amazonaws.com/023464524600-tasktape-dev/users/6df7f44e-8ef7-4217-8d0f-1216900f00ce/jobs/E49194DB-760C-4A95-9939-AF43B2DD2368/locations/BD8F9C54-0143-434C-A633-BD447B046B2F/tapes/B1428813-CF9E-40E5-AD63-6F365FF93E99/64219439862__941DA4D5-D760-459E-88B6-BE0455E27384.MOV",
                                                "createdOn": 1620501613383,
                                                "createdUser": "6df7f44e-8ef7-4217-8d0f-1216900f00ce",
                                                "height": 0
                                            }
                                        ],
                                        "tapeUuid": "B1428813-CF9E-40E5-AD63-6F365FF93E99",
                                        "version": 0,
                                        "createdUserLastName": "Mathias",
                                        "createdOn": 1620502283191,
                                        "createdUserFirstName": "Chris+6",
                                        "shareable": true,
                                        "description": "video_note_B67483D3-A2A7-4396-9AFE-9FDEE26C1723.MOV",
                                        "createdUser": "6df7f44e-8ef7-4217-8d0f-1216900f00ce",
                                        "noteUuid": "B67483D3-A2A7-4396-9AFE-9FDEE26C1723",
                                        "tags": [],
                                        "type": "VIDEO",
                                        "replies": []
                                    }
 **/