import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {useLocation} from "react-router-dom";
import {Auth} from "aws-amplify";
import {useAppContext} from "../libs/contextLib";
import {useFormFields} from "../libs/hooksLib";
import {onError} from "../libs/errorLib";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import {useHistory} from "react-router";
import {Alert, Nav, Toast} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import Api from "../libs/api";

export default function Login() {

    const history = useHistory();
    const location = useLocation();
    const {
        deviceUuid,
        setIsReceivingShare,
        setAcceptShareFailed,
        isAuthenticating,
        isAuthenticated,
        userHasAuthenticated
    } = useAppContext();
    const [authenticationFailed, setAuthenticationFailed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmShareFailed, setConfirmShareFailed] = useState(false);
    const [pageReady, setPageReady] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const api = Api();

    if (location.state) console.log(`LOGIN GOT SHARE ID FROM STATE: ${location.state.share_uuid}`);

    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: ""
    })

    useEffect(() => { //IF no args then run only once per render I think?
        if (!isAuthenticated && !isAuthenticating && !isLoading) {
            setPageReady(true);
        }
    }, [isAuthenticated, isAuthenticating])

    function validateForm() { // TODO: Toast "bad inputs" https://react-bootstrap.github.io/components/toasts/
        return fields.email.length > 0 && fields.password.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);

        try {
            await Auth.signIn(fields.email, fields.password);
            console.log("User sign in successful. Redirecting to Jobs List");

            userHasAuthenticated(true);
            shareConfirmIfNeeded();

        } catch (e) {
            console.error(e);
            setAuthenticationFailed(true);
            if (e.code === "UserNotConfirmedException") {
                console.log(`User missing confirmation. Resending code to ${fields.email} `);
                await Auth.resendSignUp(fields.email);
                console.log("Confirmation code resent");
                setIsLoading(false);
                setIsConfirming(true);
            } else {
                // onError(e);
                setIsLoading(false);
            }

        }
    }

    function shareConfirmIfNeeded() {
        if (location.state && location.state.share_uuid) {
            //We came from the share or job detail page for a user who wasn't signed in
            //This could be a new desktop user, in which case there may be a marketing
            //Interest (Thanks for trying the desktop Tasktape!)
            api.confirmShare(location.state.share_uuid, deviceUuid)
                .then((share) => {
                    setIsReceivingShare(false);
                    history.push("/");
                })
                .catch((err) => {
                    console.log(`Problem receiving share ${err}`);
                    setAcceptShareFailed(true);
                    setConfirmShareFailed(true);
                });
        } else {
            history.push("/");
        }
    }

    const [confirmFields, handleConfirmFieldChange] = useFormFields({
        confirmationCode: ""
    });

    async function handleConfirmationSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            await Auth.confirmSignUp(fields.email, confirmFields.confirmationCode);

            console.debug("Confirm successful. Signing in");

            await Auth.signIn(fields.email, fields.password);

            userHasAuthenticated(true);

            console.debug("Post-Confirm User sign in successful. Redirecting to Jobs List");
            shareConfirmIfNeeded();

        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    function validateConfirmationForm() {
        return confirmFields.confirmationCode.length > 0;
    }

    return (
        <>
            {confirmShareFailed &&
            <Alert variant="danger" onClose={() => setAuthenticationFailed(false)} delay={6000}
                   show={!isLoading && authenticationFailed} dismissible>
                Incorrect username or password.
            </Alert>
            }
            {isConfirming === false ? renderLoginForm() : renderConfirmationForm()}
        </>
    )

    function renderConfirmationForm() {

        return (
            <div className="{styles['Signup']}">

                {/*<div className="debugPanel">*/}
                {/*    <ul>*/}
                {/*        <li>isLoading: {isLoading}</li>*/}
                {/*        <li>setIsReceivingShare: {setIsReceivingShare}</li>*/}
                {/*        <li>isAuthenticating: {isAuthenticating}</li>*/}
                {/*        <li>isAuthenticated: {isAuthenticated}</li>*/}
                {/*    </ul>*/}
                {/*</div>*/}
                <Form onSubmit={handleConfirmationSubmit}>
                    <Form.Group controlId="confirmationCode" size="lg">
                        <Form.Label>Confirmation Code</Form.Label>
                        <Form.Control
                            autoFocus
                            type="tel"
                            onChange={handleConfirmFieldChange}
                            disabled={isLoading}
                            value={confirmFields.confirmationCode}
                        />
                        <Form.Text muted>Please check your email or text messages for the code.</Form.Text>
                    </Form.Group>
                    <LoaderButton
                        block
                        size="sm"
                        type="submit"
                        variant="success"
                        isLoading={isLoading}
                        disabled={!validateConfirmationForm()}
                    >
                        Verify
                    </LoaderButton>
                </Form>
            </div>
        );
    }

    function renderLoginForm() {
        return (pageReady &&
            <>
                <style>
                    {`
                   body, html {
    height: 100%;
}
body {
	background-image:url("images/login-bg.png");
	background-repeat:no-repeat;
	background-size:cover;
	}
 nav.mb-3.navbar.navbar-expand-md.navbar-light.bg-light {
    display: none;
}
                `}
                </style>


                <div className="Login loginPage">

                    {/*<div className="debugPanel">*/}
                    {/*    <ul>*/}
                    {/*        <li>isLoading: {isLoading}</li>*/}
                    {/*        <li>setIsReceivingShare: {setIsReceivingShare}</li>*/}
                    {/*        <li>isAuthenticating: {isAuthenticating}</li>*/}
                    {/*        <li>isAuthenticated: {isAuthenticated}</li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                    <div className="loginImage">
                        <img src='/icons/logo.png'/>
                    </div>
                    <div className="loginForm">
                        <h3>login</h3>
                        <Alert variant="danger" onClose={() => setAuthenticationFailed(false)} delay={6000}
                               show={!isLoading && authenticationFailed} dismissible>
                            Incorrect username or password.
                        </Alert>
                        {/*<Toast  autohide*/}
                        {/*       style={{*/}
                        {/*           position: 'relative',*/}
                        {/*           top: 100,*/}
                        {/*           right: 10,*/}

                        {/*       }}>*/}
                        {/*    <Toast.Header>*/}
                        {/*        <img*/}
                        {/*            src="holder.js/20x20?text=%20"*/}
                        {/*            className="rounded mr-2"*/}
                        {/*            alt=""*/}
                        {/*        />*/}
                        {/*        <strong className="mr-auto">Login Failed</strong>*/}
                        {/*    </Toast.Header>*/}
                        {/*    <Toast.Body>*/}
                        {/*    </Toast.Body>*/}
                        {/*</Toast>*/}

                        <Form onSubmit={handleSubmit}>
                            <Form.Group size="lg" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    autoFocus
                                    type="email"
                                    value={fields.email}
                                    placeholder="email"
                                    disabled={isLoading}
                                    onChange={handleFieldChange}
                                />
                            </Form.Group>
                            <Form.Group size="lg" controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    value={fields.password}
                                    placeholder="password"
                                    disabled={isLoading}
                                    onChange={handleFieldChange}
                                />
                            </Form.Group>
                            <span className="forgot-password">
                            <LinkContainer to="/forgot-password">
                                <Nav.Link>Forgot Password</Nav.Link>
                            </LinkContainer>
                        </span>
                            <LoaderButton
                                block
                                size="lg"
                                type="submit"
                                isLoading={isLoading}
                                disabled={!validateForm()}
                            >
                                Login
                            </LoaderButton>
                            <Form.Text>
                        <span className="create-account">
                           Don't have an account? <LinkContainer to="/signup">
                                <Nav.Link> Register</Nav.Link>
                            </LinkContainer>
                        </span>

                            </Form.Text>
                        </Form>
                    </div>
                </div>
            </>
        )
    }
}