

import React, {useEffect, useRef, useState} from "react";
import ImageUploader from "react-images-upload"; // https://github.com/jakehartnell/react-images-upload#readme
import { v4 as uuidv4 } from 'uuid';
import Form from "react-bootstrap/Form";
import {Link, useHistory} from "react-router-dom";

import { onError } from "../../libs/errorLib";
import config from "../../config";
import "./Profile.css";
import Api from "../../libs/api";
import {useAppContext} from "../../libs/contextLib";
import LoaderButton from "../../components/LoaderButton";
import CroppableImage from "../../components/CroppableImage";
import {Badge, Card, Col, Container, Nav, Row, Toast} from "react-bootstrap";
import {useFormFields} from "../../libs/hooksLib";
import Images from "../../libs/image";
import Loading from "../../components/Loading";

export default function Profile(props) {

    const { isAuthenticated, setUser, user } = useAppContext();
    const [src, setSrc] = useState(null);

    const [fileUrl, setFileUrl] = useState(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);

    const [updatableUser, setUpdatableUser] = useState(null);
    const [profile, setProfile] = useState(null);
    const [settings, setSettings] = useState(null);
    const [showUpdateComplete, setShowUpdateComplete] = useState(false);
    const toggleShowUpdateComplete = () => setShowUpdateComplete(!showUpdateComplete);

    const api = Api();
    const images = Images();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);

    const onDrop = picture => {

        const fileReader = new FileReader();

        const file = picture[0];

        fileReader.onloadend = () => {
            // setJobImage({photoFile: file, photoUrl: fileReader.result});
            setSrc(fileReader.result);
        }
        if(file) {
            setFileUrl(fileReader.readAsDataURL(file));
        } else {
            setSrc(null);
        }

        console.log(`Image dropped ${JSON.stringify(file)}`)

    };

    //TODO: Isn't/can't this be handled by app-level js? We can't have to repeat this in every file
    useEffect(() => { //IF no args then run only once per render I think?
        if (!isAuthenticated) { // TODO: Figure out why this is soemtimes true when we just logged in!} || user.userUuid === undefined) {
            history.push(`/login`)
        }

        api.getUser()
            .then(function(serverUser) {

                setProfile(serverUser.profile || {});
                setSettings(serverUser.settings ||{});

                serverUser.profile = undefined;
                serverUser.settings = undefined;

                setUpdatableUser(serverUser);

                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);
                onError("Unable to load user profile from server");
            });

    }, [])

    const [fields, handleFieldChange] = useFormFields({
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber
    });

    function validateForm() { // TODO: Toast "bad inputs" https://react-bootstrap.github.io/components/toasts/
        return fields.firstName?.length > 0 &&
            fields.lastName?.length > 0
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);

        const svcCredentials = await api.awsSvcCredentials();

        if (!svcCredentials.authenticated) {
            //TODO: Toast
            alert("Session timed out. Please re-login");
            history.push("/login");
        }

        try {

            console.log("Updating User Info");
            updatableUser.firstName = fields.firstName;
            updatableUser.lastName = fields.lastName;
            await api.putUser(updatableUser) ; //TODO: async 3 way call here

            if (croppedImageUrl) { //If URL changed
                //Update Profile Image
                console.log("Updating Profile Image");
                console.log(`IMAGE: ${croppedImageUrl}`);
                const key = await pushProfileImageToS3(user);

                //Update Profile
                console.log("Updating Profile");
                // https://s3.amazonaws.com/023464524600-tasktape-dev/users/3bf223a3-c929-41bb-8a76-f04e7928b4eb/profile/profile_avatar-BB6AF095-C96C-47D1-B32B-0D6979152D69.jpg
                //NOTE this is different from other assets in that other assets store the raw reference and we use the
                // Resizer to obtain appropriate versions. However for Profile we store a referenceable asset http reference
                if (!profile) {
                    setProfile({});
                }
                profile.profile_image_url = `https://s3.amazonaws.com/${config.aws.s3JobBucket}/${key}`;
                setProfile(await api.putUserProfile(user, profile));
                setCroppedImageUrl(null);
            }


            //Update Settings
            console.log("TODO Updating Settings");
            //TODO: Settings panel and change detection
            // await api.putUserSettings(user, settings);

            setIsLoading(false);

            toggleShowUpdateComplete();

        } catch (e) {
            console.error(e);
            onError(e);
            setIsLoading(false);
        }
    }

    async function pushProfileImageToS3(user) {

        const key = images.getProfileImageStorageKey(user.userUuid);
        console.log(`Writing ${croppedImageUrl} to S3 at : ${key} `);

        const imageBlob = await fetch(croppedImageUrl)
            .then(res => res.blob()) // Gets the response and returns it as a blob
            .then(blob => {
                return blob;
            });

        await api.pushObjectToS3(key, imageBlob);

        return key;

    }

    return (
      (isLoading && <Loading />)
      || (!isLoading &&
        <>
            <Toast show={showUpdateComplete} onClose={toggleShowUpdateComplete}>
                <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded mr-2"
                        alt=""
                    />
                    <strong className="mr-auto">Update Complete</strong>
                    {/*<small>11 mins ago</small>*/}
                </Toast.Header>
                <Toast.Body>Profile update completed successfully.</Toast.Body>
            </Toast>

            <Form className="profile-form" onSubmit={handleSubmit}>
                <Container>
                    <Row className="profile-form-single">
                        <Col>
                            <Card style={{ width: '18rem'}}>
                                <Card.Body>
                                    <Card.Title>Login Info</Card.Title>
                                    <Card.Text>
                                        <div style={{display: "block"}}>
                                            <p>
                                                <span className="lead">Email: </span>{user.email}
                                            </p>
                                            <p>
                                                <span className="lead">Phone: </span>{user.phone}
                                            </p>
                                        </div>
                                        <div>
                                            {profile.profile_image_url !== undefined && !croppedImageUrl &&
                                            <img className="profile-img" src={images.getUrlForResizedImage(profile.profile_image_url, 200)} />
                                            }
                                            <CroppableImage src={src} setSrc={setSrc} fileUrl={fileUrl} croppedImageUrl={croppedImageUrl} setCroppedImageUrl={setCroppedImageUrl}/>
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Container>
                                <Row>
                                    <Col className="profile-upload">
                                        <ImageUploader
                                            {...props}
                                            withPreview={false}
                                            withIcon={true}
                                            onChange={onDrop}
                                            buttonText={"Choose Profile Image"}
                                            label={"Max 5mb, png or jpg"}
                                            // buttonStyles={"submit"}
                                            imgExtension={[".png", ".jpg"]}
                                            maxFileSize={5242880}
                                            singleImage={true}
                                            style={{width:"200px"}}
                                        />
                                    </Col>
                                </Row>

                            </Container>
                        </Col>
                        <Col>
                            <Form.Group size="lg" controlId="firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    autoFocus
                                    type="text"
                                    value={fields.firstName}
                                    placeholder="John"
                                    disabled={isLoading}
                                    onChange={handleFieldChange}
                                />
                            </Form.Group>
                            <Form.Group size="lg" controlId="lastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    autoFocus
                                    type="text"
                                    value={fields.lastName}
                                    placeholder="Smith"
                                    disabled={isLoading}
                                    onChange={handleFieldChange}
                                />
                            </Form.Group>
                            <div>
                                Subscriber:
                                {user.isSubscribed === true && <Badge pill variant="success">Yes</Badge>}
                                {user.isSubscribed === false && <Badge pill variant="warning">No</Badge>}
                                <p className="small">Please use the <a href="https://apps.apple.com/us/app/walkthrough-by-tasktape/id1336050583?ls=1">IOS Mobile app</a> to become a subscriber</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <LoaderButton
                                block
                                size="lg"
                                type="submit"
                                isLoading={isLoading}
                                disabled={!validateForm()}
                            >
                                Save
                            </LoaderButton>
                        </Col>
                    </Row>

                </Container>
            </Form>
        </>

      )
    )

}