import React, {useContext, useEffect, useState} from "react";
import "./Feed.css";
import {Col, Container, Row} from "react-bootstrap";
import TextNote from "./TextNote";
import ImageNote from "./ImageNote";
import VideoNote from "./VideoNote";
import AudioNote from "./AudioNote";
import NoteReplies from "./NoteReplies";
import _ from "lodash";
import {FeedContext} from "../../libs/feedContextLib";

export default function Note(props) {

    const { feed } = useContext(FeedContext);
    const [note, setNote] = useState(props.note);
    const [replies, setReplies] = useState(props.note.replies);

    useEffect(() => { //IF no args then run only once per render I think?
        setNote(props.note);
        setReplies(_.sortBy(props.note.replies, ['createdOn'], ['desc']));
    }, [props.note, props.note.replies, feed.activeNote, feed.activeReply])

    return (note &&
        <>
            <Container key={note.noteUuid}>
                <Row className="mt-3">
                    <Container>
                        {note.type === 'TEXT' &&   <TextNote note={note} />}
                        {note.type === 'IMAGES' && <ImageNote note={note} />}
                        {note.type === 'VIDEO' &&  <VideoNote note={note} />}
                        {note.type === 'AUDIO' &&  <AudioNote note={note} />}
                        <NoteReplies note={note} replies={replies} />
                    </Container>
                </Row>
               
            </Container>
        </>
    );
}
