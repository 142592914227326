import ResizedImage from "../../components/ResizedImage";
import React, {useEffect, useState} from "react";
import TaskViewTapes from "./TaskViewTapes";
import {useJobContext} from "../../libs/jobContextLib";

export default function AreaTaskView(props) {

    const { jobDetail } = useJobContext();

    if (! props.selectedTaskView) {
        return (
            <>
                <div className="font-weight-bold text-center">Job Details</div>
                <div className="text-center" id="area-task-view">
                    <ResizedImage
                        thumbnail={true}
                        url={jobDetail.jobImage.contentUrl}
                        width={300}
                    />
                </div>
            </>
        );
    } else {
        return ( props.selectedTaskView &&
            <>
                <TaskViewTapes taskView={props.selectedTaskView} />
            </>
        );
    }

}