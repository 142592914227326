import React, {useEffect} from "react";
import {Accordion} from "react-bootstrap";
import _ from "lodash";
import JobDetailArea from "./JobDetailArea";
import {useHistory} from "react-router-dom";
import {SET_AREA, SET_TASK_TAPE, SET_TASK_VIEW, updateDeepLink} from "./DeepLinkActions";
import {useJobContext} from "../../libs/jobContextLib";

export default function JobDetailAreas(props) {

    const history = useHistory();

    const { deepLinkIds, dispatch } = useJobContext();


    useEffect(() => { //IF no args then run only once per render I think?
        selectedArea(deepLinkIds.areaUuid);
    }, [deepLinkIds.areaUuid])

    const areas = _.orderBy(props.areas, ['createdOn'], ['desc']);

    function selectedArea(areaUuid) {
        const selectedAreaUuid = areaUuid || areas[0].locationUuid;
        dispatch({ type: SET_AREA, payload: selectedAreaUuid });

        const taskView = document.getElementById("area-task-view");
        if (taskView) taskView.scrollTo(0,0);

        if (areaUuid) { //If updating the selected area, update the url bar
            updateDeepLink(history, deepLinkIds);
        }
    }

    return (
        <>
			<div className="acc-title"><h4>Job areas</h4></div>
            <Accordion defaultActiveKey={areas.length > 0 && deepLinkIds.areaUuid}>
            {areas.map((area) =>
                <div onClick={() => selectedArea(area.locationUuid)} key={area.locationUuid}>
                    <JobDetailArea
                        key={area.locationUuid}
                        area={area}
                        taskViewSelected={props.taskViewSelected}
                    />
                </div>
            )}
            </Accordion>

        </>
    );
}
