import React from 'react'
import Avatar from 'react-avatar';
import Images from '../libs/image';
import { getUsername } from '../libs/models';

export default function TTAvatar(props) {

    const images = Images();
    const user = props.user;
    let profileImage = undefined;


    if (user.profile && user.profile.profile_image_url) {
        profileImage = images.getUrlForResizedImage(user.profile.profile_image_url, 200);
    }

    // const userPic = user. TODO: From resizer

    //TODO: NOt forcing rerender if user updated outside of here (i.e. post login)
    //TODO: Need to sign the s3 url in user.profile.profile_image_url
    if (user.userUuid) {
        return(
            <Avatar round size={props.size || 45} name={ getUsername(user) } src={ profileImage } />
        )
    } else {
        return null;
    }
}