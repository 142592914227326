import React, {useContext, useState} from "react";
import "./Feed.css";
import {Carousel, Col, Container, Image, Modal, Row} from "react-bootstrap";
import Images from "../../libs/image";
import {Delete} from "@material-ui/icons";
import _ from "lodash";
import {FeedContext} from "../../libs/feedContextLib";
import {CANCEL_EDIT_NOTE, noteActions, PUT_NOTE, SELECT_NOTE} from "../../containers/tasktape/NoteInterfaceActions";
import {onError} from "../../libs/errorLib";
import Form from "react-bootstrap/Form";
import LoaderButton from "../LoaderButton";
import Moment from "react-moment";

export default function ImageNoteContent(props) {

  const {feed, dispatch} = useContext(FeedContext);
  const [show, setShow] = useState(null);
  const [note, setNote] = useState(props.note);
  const [activeImage, setActiveImage] = useState({});

  const width = 150; //This needs to be media-query-ified?
  const images = Images();

  const handleImageModalClose = () => {
    setActiveImage({});
  }
  const handleImageModalShow = (image) => {
    setActiveImage(image);
    setShow(image.mediaUuid);
  }

  function doneEditNote(e) {
    e.preventDefault();
    dispatch({type: CANCEL_EDIT_NOTE});
  }

  async function deleteImageFromList(e, mediaUuid) {
    e.preventDefault();
    dispatch({type: PUT_NOTE, payload: note});

    _.remove(note.content, (iterImg) => iterImg.mediaUuid === mediaUuid);

    //TODO: Async call to Lambda/API to delete the image from S3
    // TODO: Make sure this user has not that priv!!

    try {
      const updatedNote = await noteActions.putNote(note);

      dispatch({type: SELECT_NOTE, payload: updatedNote});

      if (updatedNote.content.length === 0) { //No more content, delete note
        await noteActions.deleteNote(updatedNote);
        dispatch({type: CANCEL_EDIT_NOTE});
      }
    } catch (e) {
      //TODO: UX Info
      onError(e);
    }

  }

  function getImage(image) {
    return <Image src={images.getUrlForResizedImage(image.contentUrl, 500)}
                  onClick={() => handleImageModalShow(image)}
                  // className="d-block w-100"
                  key={image.mediaUuid}
                  thumbnail
                  style={{
                    width: 500,
                    cursor: 'pointer'
                  }}/>
  }

  return (
    <>

      <Row>
        <Col className="modal-image-main">
          <Container fluid>
            <Row>
              {note?.content?.map((image) =>
                <Col>
                  <div className="image-note-layout">
                    {/*If we're not editing, show the list*/}
                    {!props.edit &&
                      getImage(image)
                    }

                    {/*If we're editing, show the list with deletes & done icon*/}
                    {props.edit &&

                    <div id="deleteButtonWrap">
                      {getImage(image)}
                      <div className="px-2"
                        // style={{
                        //   // display: "absolute",
                        //   padding: "2px",
                        //   // top: -50,
                        //   // left: -170,
                        //   backgroundColor: "white",
                        //   border:"none"
                        // }}
                      >
                        <Delete fontSize="inherit" color="primary" style={{cursor: 'pointer', verticalAlign: 'top'}}
                                onClick={(e) => deleteImageFromList(e, image.mediaUuid)}/>
                      </div>
                    </div>
                    }

                  </div>
                </Col>
              )}
            </Row>
            <Row>
              {props.edit &&
              <LoaderButton
                size="md"
                type="save"
                variant="primary"
                isLoading={feed.saving}
                // disabled={!validateReply}
                onClick={(e) => doneEditNote(e)}
              >
                Done
              </LoaderButton>
              }
            </Row>
          </Container>
        </Col>
      </Row>
      <Modal
        show={show === activeImage.mediaUuid}
        onHide={handleImageModalClose}
        dialogClassName="modal-90w" className="image-modal-main"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image
            src={activeImage.contentUrl}
            style={{
              width: "100%"
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          Taken on: {new Date(activeImage.createdOn).toLocaleDateString()}
        </Modal.Footer>
      </Modal>
    </>
  );
}