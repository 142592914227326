import React, {useContext, useEffect, useState} from "react";
import "./Feed.css";
import _ from "lodash";
import NoteReplyContent from "./NoteReplyContent";
import {FeedContext} from "../../libs/feedContextLib";

export default function NoteReplies(props) {

    // const { feed } = useContext(FeedContext);
    const [replies, setReplies] = useState([]);

    useEffect(() => { //IF no args then run only once per render I think?
        setReplies(_.sortBy(props.replies, ['createdOn'], ['desc']));
    }, [props.replies])

    return (
        <>
            
        {replies.map((reply) =>
            <NoteReplyContent key={reply.replyUuid} note={props.note} reply={reply} />
        )}
        </>
    );
}
