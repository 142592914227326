
// export function hardenedValue(entity, path) {
//
//     path.split('.').forEach((elem) => {
//
//     })
//     return entity[path]
// }


import _ from "lodash";
import {v4 as uuidv4} from "uuid";
import Note from "../components/notes/Note";

export const constants = {
    DEFAULT_UNSAVED_UUID: "UNSAVED_IMAGE_NOTE",
    DEFAULT_UNSAVED_REPLY_UUID: "UNSAVED_REPLY"
}

export function getUsername(user) {
    return user.firstName ? user.firstName + " " + user.lastName : user.username;
}

export function hardenFunkyJob(job) {

    if (job.jobImage === undefined) {
        job.jobImage = {}; //prevent unwanted 'cannot read property of undefined' in case app didn't add one
    }

}

export function hasReplies(note) {
    return note.replies !== undefined && note.replies.length > 0;
}

export function newReply(job, locationUuid, note, user) {
    return {
        replyUuid: constants.DEFAULT_UNSAVED_REPLY_UUID,
        content: "",
        readBy: [],
        jobOwnerUuid: job.createdUser,
        createdUser: user.userUuid,
        createdOn: new Date().getTime(),
        jobUuid: job.jobUuid,
        locationUuid: locationUuid,
        tapeUuid: note.tapeUuid,
        noteUuid: note.noteUuid,
        deleted: false,
    };
}

export function newImage(user, originalUrl, contentUrl, mediaUuid) {
    return {
      "mediaUuid": mediaUuid || uuidv4(),
      "originalUrl": originalUrl,
      "contentUrl": contentUrl,
      // "width": 0,// TODO??
      "createdOn": new Date().getTime(),
      "createdUser": user.userUuid
    }
}
export function getUpdateableNotee(jobDetail, reply) {
    const replyArea = _.find(jobDetail.locations, function(jobArea) {
        if (jobArea.locationUuid === reply.locationUuid) {
            return jobArea.tapeAnchors;
        }
    });
    const tapeAnchors = _.flatMap(replyArea.tapeAnchors, 'tapes');
    const tape = _.find(tapeAnchors, function(tape) {
        if (tape.tapeUuid === reply.tapeUuid) {
            return tape;
        }
    });

    const note = _.find(_.flatMap(tape.notes), function(note) {
        if (note.noteUuid === reply.noteUuid) {
            return note;
        }
    });

    return note;
}