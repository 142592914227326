export function onError(error) {
    console.log(JSON.stringify(error));
    let message = error.toString();

    // Auth errors
    if (!(error instanceof Error) && error.message) {
        message = error.message;
    }

    if (process.env.REACT_APP_STAGE !== "prod") {
        alert(message);
    }

}