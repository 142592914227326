import React, {useContext, useEffect, useState} from "react";
import "./Feed.css";
import {Alert, Col, Container, Row} from "react-bootstrap";
import {Delete, DeleteOutlined, Edit, EditOutlined} from "@material-ui/icons";
import Authorization from "../../libs/authorization";
import TTAvatar from "../TTAvatar";
import Moment from "react-moment";
import ReplyIcon from "@material-ui/icons/Reply";
import {hasReplies, newReply} from "../../libs/models";
import Images from "../../libs/image";
import {FeedContext, useFeedContext} from "../../libs/feedContextLib";
import {
  CANCEL_EDIT_NOTE,
  DELETE_NOTE,
  EDIT_NOTE,
  NEW_REPLY, noteActions, SELECT_NOTE
} from "../../containers/tasktape/NoteInterfaceActions";
import {useJobContext} from "../../libs/jobContextLib";
import {useAppContext} from "../../libs/contextLib";
import _ from "lodash";

export default function NoteHeader(props) {

  const { feed, dispatch } = useContext(FeedContext);
  const {jobDetail} = useJobContext();
  const {user} = useAppContext();

  const [note, setNote] = useState(props.note);
  const [alerting, setAlerting] = useState(false);
  const [reason, setReason] = useState("");
  const noteAuth = Authorization().notes;
  const images = Images();

  useEffect(() => { //IF no args then run only once per render I think?
    setNote(props.note);
  }, [props.note, props.note.replies, feed.activeNote])

  function replyNote() {

    // Create a new empty reply
    const createdReply = newReply(jobDetail, feed.activeTape.locationUuid, note, user);

    note.replies.push(createdReply);
    dispatch({type: SELECT_NOTE, payload: note});
    // feed.activeNote.
    dispatch({type: NEW_REPLY, payload: createdReply});

  }

  async function deleteNote(e) {
    e.preventDefault();
    dispatch({type: DELETE_NOTE, payload: note});

    await noteActions.deleteNote(note);

    dispatch({type: CANCEL_EDIT_NOTE, payload: note});

  }

  function cannotEdit() {
    setReason("Cannot edit this because it has replies. Please use a reply.");
    setAlerting(true);
  }

  function cannotDelete() {
    setReason("Cannot delete this because it has replies.");
    setAlerting(true);
  }

  return (
    <>

      <Alert variant="danger" onClose={() => setAlerting(false)} show={alerting} dismissible>
        {reason}
      </Alert>
      <TTAvatar size={30} user={images.buildUserForAvatar(note)}/>
      <span className="px-2" style={{fontSize: 16, textAlign: "right"}}>
        <Moment format="hh:mm A on MM.DD.YY">{note.createdOn}</Moment>
      </span>
      <span className="float-right">
        {!feed.saving && <ReplyIcon fontSize="inherit" color="primary" style={{cursor: 'pointer'}} onClick={() => replyNote()}/>}
        {
          noteAuth.canEdit(note) && !hasReplies(note) && !feed.saving &&
          <span className="px-2"><Edit fontSize="inherit" color="primary" style={{cursor: 'pointer'}}
                                       onClick={() => dispatch({type: EDIT_NOTE, payload: note})}/></span>
        }
        {
          noteAuth.canEdit(note) && hasReplies(note) && !feed.saving &&
            <span className="px-2"><EditOutlined fontSize="inherit" color="disabled" style={{cursor: 'pointer'}}
                                               onClick={() => cannotEdit()}/></span>
        }
        {
          noteAuth.canDelete(note) && !hasReplies(note) && !feed.saving &&
          <span className="px-2"><Delete fontSize="inherit" color="primary" style={{cursor: 'pointer'}}
                                         onClick={(e) => deleteNote(e)}/></span>
        }
        {
          noteAuth.canDelete(note) && hasReplies(note) && !feed.saving &&
          <span className="px-2"><DeleteOutlined fontSize="inherit" color="disabled" style={{cursor: 'pointer'}}
                                                 onClick={() => cannotDelete()}/></span>
        }
      </span>

    </>
  );
}