import ReactDOM from 'react-dom';
import React, {forwardRef, useEffect, useState} from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {BsArrowRepeat} from "react-icons/bs";
import Button from "react-bootstrap/Button";

export default function CroppableImage(props) {

    let fileUrl = props.fileUrl;
    let croppedImageUrl = props.croppedImageUrl;
    const [pickedImage, hasPickedImage] = useState(false);
    const setCroppedImageUrl = props.setCroppedImageUrl;

    const [imageRef, setImageRef] = useState(null);
    const [crop, setCrop] = useState({
        unit: 'px',
        x: 100,
        y: 100,
        width: 150,
        aspect: 1,
        minWidth: 132,
        minHeight:132,
        keepSelection: true
    });

    // If you setState the crop in here you should return false.
    const onImageLoaded = image => {
        setImageRef(image);
    };

    const onCropComplete = crop => {
        makeClientCrop(crop);
    };

    const onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        setCrop(crop);
    };

    useEffect(() => { //IF no args then run only once per render I think?
        if (props.src) {
            setCroppedImageUrl(props.src);
        }
    }, [])

    function handleCroppingDone(e) {
        e.preventDefault();
        hasPickedImage(true);
        console.log("finished cropping");
        // props.setSrc(null);
    }

    async function makeClientCrop(crop) {
        if (imageRef && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(
                imageRef,
                crop,
                // 'croppedJobImage.jpeg'
            'newFile.jpeg'
            )
            setCroppedImageUrl(croppedImageUrl);
        }
    }

    function getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(fileUrl);
                fileUrl = window.URL.createObjectURL(blob);
                resolve(fileUrl);
            }, 'image/jpeg');
        });
    }

    return (
        <>
            {props.src && !pickedImage && (
                <ReactCrop
                    src={props.src}
                    crop={crop}
                    ruleOfThirds
                    onImageLoaded={onImageLoaded}
                    onComplete={onCropComplete}
                    onChange={onCropChange}
                />
            )}
            {croppedImageUrl && (
                <>
                    <img alt="Crop" style={{maxWidth: '100%'}} src={croppedImageUrl}/>
                    <Button
                        // className={`LoaderButton ${className}`}
                        // variant="primary"
                        size="sm"
                        onClick={handleCroppingDone}
                        disabled={pickedImage}
                    >
                    Use Image
                    </Button>
                </>
            )}
        </>
    )
}
