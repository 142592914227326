import React, {useEffect, useState} from "react";
import {v4 as uuidv4} from 'uuid';
import Routes from "./Routes";
import Navbar from "react-bootstrap/Navbar";
import {LinkContainer} from "react-router-bootstrap";
import {Auth} from "aws-amplify";
import {Accordion, Card, Col, Dropdown, Image, ListGroup, Nav, OverlayTrigger, Popover, Row} from "react-bootstrap";
import {AppContext} from "./libs/contextLib";
import {useHistory} from "react-router";
import _ from "lodash";
import {onError} from "./libs/errorLib";
import Api from "./libs/api";
import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {
    faListUl,
    faBell,
    faBars,
    faCog,
    faShareSquare,
    faMapMarkedAlt,
    faFileDownload,
    faEnvelopeOpenText,
    faTrashAlt,
    faCamera,
    faVideo,
    faCommentAlt,
    faVolumeUp,
    faCircle,
    faTimesCircle,

    faPlusCircle,


} from '@fortawesome/free-solid-svg-icons';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-image-crop/lib/ReactCrop.scss';
import "./App.scss";
import TTAvatar from "./components/TTAvatar";
import Moment from "react-moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Images from "./libs/image";
import {useLocation} from "react-router-dom";
import {updateDeepLink} from "./containers/tasktape/DeepLinkActions";
import ImageNoteContent from "./components/notes/ImageNoteContent";

library.add(fab, faListUl, faBell, faBars, faCog, faShareSquare, faMapMarkedAlt, faFileDownload, faEnvelopeOpenText, faTrashAlt, faCamera, faVideo, faCommentAlt, faVolumeUp, faCircle, faTimesCircle, faPlusCircle);

/**
 *
 * The useEffect hook takes a function and an array of variables. The function will be called every time the component is rendered. And the array of variables tell React to only re-run our function if the passed in array of variables have changed. This allows us to control when our function gets run. This has some neat consequences:

 If we don’t pass in an array of variables, our hook gets executed everytime our component is rendered.
 If we pass in some variables, on every render React will first check if those variables have changed, before running our function.
 If we pass in an empty list of variables, then it’ll only run our function on the FIRST render.
 */

function App() {
    // ==============================================
    const [itemClass, setItemClass] = useState({"a": "mx-2", "b": "mx-2", "c": "mx-2"});
    const mainClasses = {"a": "mx-2", "b": "mx-2", "c": "mx-2"};
    const activeToggle = (elem) => {
        const dataId = elem.target.getAttribute("data");
        setItemClass({...mainClasses, [dataId]: "mx-2 active"});
        // console.log("A", dataId);

    }
    // ==============================================

    const history = useHistory();
    const location = useLocation();
    const api = Api();
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [isReceivingShare, setIsReceivingShare] = useState(false);
    const [user, setUser] = useState({});
    const [notifications, setNotifications] = useState([]);
    const [notificationsShowing, setNotificationsShowing] = useState(false);
    // const [notificationTarget, setNotificationTarget] = useState(null);

    if (!localStorage.getItem('deviceUuid')) {
        localStorage.setItem('deviceUuid', uuidv4());
        console.log(`deviceUuid not present. either new-user or cleared-storage. New deviceUuid is ${localStorage.getItem('deviceUuid')}`);
    }

    const deviceUuid = localStorage.getItem('deviceUuid');

    const redirectPaths = [
        "/login",
        "/signup",
    ];

    const noRedirectPaths = [
        "/shares",
    ];

    const images = Images();

    useEffect(() => {
        console.log("App.js.useEffect");
        onLoad();
    }, []);

    function onLoad() {
        try {
            console.log(`App.js.onLoad for path: ${location.pathname}`);

            Auth.currentSession()
                .then((authResult) => {
                    console.log("User is already authenticated");

                    api.getUser()
                        .then((ttUser) => {
                            //TODO: Make this stop happening more than once!
                            setUser(ttUser);
                            console.log(`User API Response: ${JSON.stringify(ttUser)}`);
                            userHasAuthenticated(true);

                            if (redirectPaths.includes(location.pathname)) {
                                history.push("/jobs/list");
                            }

                        })
                        .catch((err) => onError(err));

                    api.getUserNotifications()
                        .then((notifications) => {
                            setNotifications(notifications.replies);
                        })
                        .catch((err) => onError(err));
                })
                .catch((e) => {
                    if (e !== 'No current user') {
                        onError(e);
                    }
                    const shouldRedirect = !_.find(noRedirectPaths, function (path) {
                        return location.pathname.indexOf(path) > -1;
                    })
                    if (shouldRedirect) {
                        history.push("/login");
                    }
                })
                .finally(() => {
                    setIsAuthenticating(false);
                    setIsReceivingShare(location.pathname.indexOf("/shares") > -1);
                });

        } catch (e) {
            console.log(`Unexpected error ${e}`);

        }

    }

    async function handleLogout() {
        await Auth.signOut();

        userHasAuthenticated(false);
        sessionStorage.clear();

        history.push("/login");
    }

    async function notificationClicked(selectedReplyUuid) {
        const noteSelected = _.find(notifications, {replyUuid: selectedReplyUuid});
        setNotificationsShowing(false);
        updateDeepLink(history, {
            jobUuid: noteSelected.jobUuid,
            areaUuid: noteSelected.locationUuid,
            taskViewUuid: "lookup",
            taskTapeUuid: noteSelected.tapeUuid
        });
        // setNotifications(_.remove(notifications, (note) => note.replyUuid !== selectedReplyUuid));
        // await api.readReply(selectedReplyUuid);
    }

    async function dismissNotification(evt, selectedReplyUuid) {
        evt.preventDefault();
        setNotifications(_.remove(notifications, (note) => note.replyUuid !== selectedReplyUuid));
        await api.readReply(selectedReplyUuid);
    }

    function notificationPopover() {
        return (
            <Popover id="popover-basic">
                <div className="notification-inner">
                    <Popover.Title as="h3">Recent Notifications</Popover.Title>
                    <Popover.Content>
                        {notificationsList()}
                    </Popover.Content>
                </div>
            </Popover>
        )
    }

    function notificationsList() {
        return (
            <ListGroup>
                {notifications.map((notification) =>

                        <Card key={notification.replyUuid}>
                            <Card.Header>
                                <b>Job:</b> {notification.jobName}
                                <FontAwesomeIcon icon="times-circle" color="red" size="2x" className="fa-pull-right" onClick={(evt) => dismissNotification(evt, notification.replyUuid)} style={{cursor: 'pointer'}} />
                            </Card.Header>
                            <Card.Body>
                                <ListGroup.Item action onClick={() => notificationClicked(notification.replyUuid)} key={notification.replyUuid}>
                                <TTAvatar size={70} user={images.buildUserForAvatar(notification)}/>
                                <div className="text-main">
                                    {!(notification.content instanceof Array) &&
                                    <span>{notification.content}</span>
                                    }

                                    {notification.content instanceof Array &&
                                        notification.content.map((image) =>
                                          <Image src={images.getUrlForResizedImage(image.contentUrl, 100)}
                                                 key={image.mediaUuid}
                                                 thumbnail
                                                 style={{
                                                     width: 100
                                                 }}/>
                                        )
                                    }

                                    <Moment fromNow>{notification.createdOn}</Moment>
                                </div>
                                </ListGroup.Item>
                            </Card.Body>
                        </Card>
                )}
            </ListGroup>
        );
    }

    return (
        !isAuthenticating && (
            <div className="App py-3">
                <span><h5 style={{color: "red"}}>== DESKTOP APPLICATION BETA ({process.env.REACT_APP_STAGE}) ==</h5></span>

                <Navbar collapseOnSelect bg="light" expand="md" className="mb-3 sticky-top">
                    <LinkContainer to="/">
                        <Navbar.Brand className="font-weight-bold text-muted">
                            <img className="brand-icon-desktop" src="/icons/WalkThrough@2x.png"/> by TaskTape
                        </Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle/>

                    <img className="brand-mobile-logo" src="/icons/tape-icon-drag@3x.png" width="65"/>

                    <Navbar.Collapse className="justify-content-end">
                        <Nav activeKey={window.location.pathname} className="mx-1">
                            {isAuthenticated ? (
                                <>
                                    <LinkContainer to="/jobs/list" className={itemClass.a} onClick={activeToggle}
                                                   data="a">
                                        <Nav.Link>
                                            <span className="fa-layers fa-fw">
                                              <FontAwesomeIcon icon="circle" color="#1a67d3" size="2x"/>
                                              <FontAwesomeIcon icon="list-ul" color="#1a67d3" inverse
                                                               transform="shrink-7" size="2x"/>
                                            </span>
                                        </Nav.Link>
                                    </LinkContainer>
                                    <Navbar.Text className="mx-2" style={{cursor: "pointer"}}>
                                        <OverlayTrigger trigger="click" placement="bottom" overlay={notificationPopover}
                                                        defaultShow={notificationsShowing}
                                                        onToggle={() => setNotificationsShowing(!notificationsShowing)}
                                                        show={notificationsShowing}>
                                            <span className="fa-layers fa-fw">
                                              <FontAwesomeIcon icon="circle" color="#1a67d3" size="2x"/>
                                              <FontAwesomeIcon icon="bell" color="#1a67d3" inverse transform="shrink-7"
                                                               size="2x"/>
                                                {notifications.length > 0 &&
                                                <span className="fa-layers-counter fa-custom-counter"
                                                      style={{background: "Tomato"}}>{notifications.length}</span>}
                                            </span>
                                        </OverlayTrigger>
                                    </Navbar.Text>
                                    <LinkContainer to="/user" className={itemClass.c} onClick={activeToggle} data="c">
                                        <Nav.Link>
                                            <div className="lgUser"><TTAvatar user={user}/></div>
                                        </Nav.Link>
                                    </LinkContainer>

                                    <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                                    {/*<LinkContainer to="#">*/}
                                    {/*<Dropdown.Menu show>*/}
                                    {/*    <Dropdown.Header><Nav.Link><img src="/icons/settings_inactive.png" width="33" height="33" /></Nav.Link></Dropdown.Header>*/}
                                    {/*    <Dropdown.Item eventKey="2">Profile</Dropdown.Item>*/}
                                    {/*    <Dropdown.Item eventKey="3">Settings</Dropdown.Item>*/}
                                    {/*    <Dropdown.Divider />*/}
                                    {/*    <Dropdown.Item eventKey="4"><Nav.Link onClick={handleLogout}>Logout</Nav.Link></Dropdown.Item>*/}
                                    {/*</Dropdown.Menu>*/}
                                    {/*</LinkContainer>*/}

                                </>
                            ) : (
                                <>
								<LinkContainer to="https://tasktape.com/support/">
                                        <Nav.Link>support</Nav.Link>
                                    </LinkContainer>
									<LinkContainer to="https://tasktape.com/">
                                        <Nav.Link>Download</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to="/signup">
                                        <Nav.Link>Signup</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to="/login">
                                        <Nav.Link>Login</Nav.Link>
                                    </LinkContainer>
                                </>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <div className="Available-content list-m-hide-title">Available on
                    <div className="Available-icons">
                        {/* <img src="icons/app_store.png" alt=""/>*/}
                        <a href="https://apps.apple.com/us/app/walkthrough-by-tasktape/id1336050583?ls=1"
                           target="_blank"> <img src="/icons/ios_icon.png" alt="ios_icon"/> </a>
                        <a href="https://play.google.com/store/apps/details?id=com.solipsar.tasktape.walkthrough.dev&ah=QDNLFWRDhWF05wzGIv4J3vnBsjA"
                           target="_blank"> <img src="/icons/android_icon.png" alt="android_icon"/> </a>
                    </div>
                </div>
                <AppContext.Provider value={{
                    deviceUuid,
                    isAuthenticating,
                    isAuthenticated,
                    isReceivingShare,
                    setIsReceivingShare,
                    userHasAuthenticated,
                    user
                }}>
                    <Routes/>
                </AppContext.Provider>

            </div>
        )
    );
}

export default App;