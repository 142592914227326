import React, {useEffect, useReducer, useRef, useState} from "react";
import {Accordion, Card, Image} from "react-bootstrap";
import _ from "lodash";
import Images from "../../libs/image";
import ResizedImage from "../../components/ResizedImage";
import {useJobContext} from "../../libs/jobContextLib";
import {useHistory} from "react-router-dom";
import {SET_AREA, SET_TASK_TAPE, SET_TASK_VIEW, updateDeepLink} from "./DeepLinkActions";

export default function JobDetailArea(props) {

    /**
     * NOTE: TaskView === TapeAnchor (old data model vs. new terminology)
     */

    const { deepLinkIds, dispatch } = useJobContext();
    const history = useHistory();
    const images = Images();

    function setSelectedTaskView(taskView) {
        const taskViewId = taskView?.tapeAnchorUuid;
        const selectedUuid = taskViewId || props.area.tapeAnchors[0].tapeAnchorUuid;

        dispatch({ type: SET_TASK_VIEW, payload: selectedUuid });
        dispatch({ type: SET_AREA, payload: props.area.locationUuid });

        if (taskViewId) { //If updating the selected area, update the url bar
            updateDeepLink(history, deepLinkIds);
            props.taskViewSelected(taskView);
        }
    }

    useEffect(() => { //IF no args then run only once per render I think?
        if (deepLinkIds.taskViewUuid) {
            const selectedTaskView = _.find(props.area.tapeAnchors, { tapeAnchorUuid: deepLinkIds.taskViewUuid });
            if (selectedTaskView) {
                setSelectedTaskView(selectedTaskView);
            }
        }
    }, [deepLinkIds.taskViewUuid, props.area])

    return (props.area &&

        <Card>

            <Accordion.Toggle as={Card.Header}
                              eventKey={props.area.locationUuid} style={{cursor: 'pointer'}}>
                <div className="area-left"><ResizedImage
                    roundedCircle={true}
                    url={props.area.tapeAnchors.length > 0 ? props.area.tapeAnchors[0].image.contentUrl : undefined}
                    width="30"
                    height="30"
                /></div>
                <div className="area-right">{props.area.name} </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={props.area.locationUuid}>
                <>
                {props.area.tapeAnchors.map((taskView) =>
                    <Card.Body key={taskView.tapeAnchorUuid} onClick={() => setSelectedTaskView(taskView)} variant="link"
                               className={deepLinkIds.taskViewUuid === taskView.tapeAnchorUuid ? 'selected-taskview' : 'panel-wrap'} >
                        <Image key={taskView.tapeAnchorUuid} onClick={() => props.taskViewSelected(taskView)}
                               style={{cursor: 'pointer'}}
                               src={images.getUrlForResizedImage(taskView.image.contentUrl, 200)}
                        />
                    </Card.Body>
                )}
                </>
            </Accordion.Collapse>
        </Card>
    );
}
