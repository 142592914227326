import {Card, Col, Image, Modal, Row, Spinner} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import ResizedImage from "../../components/ResizedImage";
import Feed from "../../components/notes/Feed";
import {act} from "@testing-library/react";
import {useAppContext} from "../../libs/contextLib";
import {
    CheckCircle,
    CheckCircleOutline, Edit,
    Message, PhotoCamera, RadioButtonUnchecked,
    Videocam,
    VolumeDown
} from "@material-ui/icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Api from "../../libs/api";
import {onError} from "../../libs/errorLib";
import "./TaskViewTapes.css";
import Authorization from "../../libs/authorization";
import Form from "react-bootstrap/Form";
import LoaderButton from "../../components/LoaderButton";
import _ from "lodash";
import {useHistory} from "react-router-dom";
import {useJobContext} from "../../libs/jobContextLib";
import {SET_AREA, SET_TASK_TAPE, SET_TASK_VIEW, updateDeepLink} from "./DeepLinkActions";
import Loading from "../../components/Loading";
import FeedContextProvider from "../../libs/feedContextLib";


export default function TaskViewTapes(props) {
    const taskView = props.taskView;
    const [activeTape, setActiveTape] = useState({});
    const [editingTapeName, setEditingTapeName] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [notesShowing, setNotesShowing] = useState(false);
    const [isUpdatingTaskComplete, setIsUpdatingTaskComplete] = useState(false);
    const [tapeNameText, setTapeNameText] = useState(activeTape.name);
    const [taskViewImageLoaded, setTaskViewImageLoaded] = useState(false);
    const api = Api();
    const notesAuth = Authorization().notes;
    const tapesAuth = Authorization().tapes;

    const tapeImageRatio = 0.45;

    const history = useHistory();

    const { deepLinkIds, dispatch } = useJobContext();

    useEffect(() => { //IF no args then run only once per render I think?
        if (deepLinkIds.taskTapeUuid) {
            const deepLinkedTape = _.find(taskView.tapes, { tapeUuid: deepLinkIds.taskTapeUuid });
            if (deepLinkedTape) {
                showTapeNotes(deepLinkedTape);
            } else {
                console.log(`We have a deep link to a tape: ${deepLinkIds.taskTapeUuid} but did not find a matching tape in ${JSON.stringify(taskView.tapes)}`);
            }

        }
    }, [deepLinkIds.taskTapeUuid, props.taskView])

    function placeTape(tape, tapeIndex) {

        const left = taskView.image.width * tapeImageRatio * (tape.centerXPercentage/100);
        const top = taskView.image.height * tapeImageRatio * (tape.centerYPercentage/100);

        const hasImageNote = tape.notes.IMAGES !== undefined;
        const imageCount = tape.notes.IMAGES ? tape.notes.IMAGES.length : 0;
        const hasTextNote = tape.notes.TEXT !== undefined;
        const textCount = tape.notes.TEXT ? tape.notes.TEXT.length : 0;
        const hasVideoNote = tape.notes.VIDEO !== undefined;
        const videoCount = tape.notes.VIDEO ? tape.notes.VIDEO.length : 0;
        const hasAudioNote = tape.notes.AUDIO !== undefined;
        const audioCount = tape.notes.AUDIO ? tape.notes.AUDIO.length : 0;

        return (
            taskViewImageLoaded &&
            <div key={tape.tapeUuid}
                 style={{
                     position:"absolute",
                     zIndex:2,
                     left:left-37,
                     top:top-38,
                     border:"none"
                 }}>
                <Image
                    src="/icons/tape-sm@3x@2x.png"
                    style={{
                        cursor: "pointer"

                    }}
                    onClick={() => showTapeNotes(tape)}
                />
                <div className="tape-annotations">
                {hasImageNote &&
                    <span className="fa-layers fa-fw image-note">
                      <FontAwesomeIcon icon="circle" color="#1a67d3" size="2x" />
                      <FontAwesomeIcon icon="camera" color="#1a67d3" inverse transform="shrink-6" size="2x" />
                      {imageCount > 1 && <span className="fa-layers-counter fa-custom-counter" style={{background:"Tomato"}}>{imageCount}</span>}
                    </span>

                }
                {hasTextNote &&
                    <span className="fa-layers fa-fw text-note">
                      <FontAwesomeIcon icon="circle" color="#1a67d3" size="2x" />
                      <FontAwesomeIcon icon="comment-alt" color="#1a67d3" inverse transform="shrink-6" size="2x" />
                      {textCount > 1 && <span className="fa-layers-counter fa-custom-counter" style={{background:"Tomato"}}>{textCount}</span>}
                    </span>
                }
                {hasVideoNote &&
                    <span className="fa-layers fa-fw video-note">
                      <FontAwesomeIcon icon="circle" color="#1a67d3" size="2x" />
                      <FontAwesomeIcon icon="video" color="#1a67d3" inverse transform="shrink-6" size="2x" />
                      {videoCount > 1 && <span className="fa-layers-counter fa-custom-counter" style={{background:"Tomato"}}>{videoCount}</span>}
                    </span>
                }
                {hasAudioNote &&
                    <span className="fa-layers fa-fw audio-note">
                      <FontAwesomeIcon icon="circle" color="#1a67d3" size="2x" />
                      <FontAwesomeIcon icon="volume-up" color="#1a67d3" inverse transform="shrink-6" size="2x" />
                      {audioCount > 1 && <span className="fa-layers-counter fa-custom-counter" style={{background:"Tomato"}}>{audioCount}</span>}
                    </span>
                }
                </div>
            </div>
        )
    }

    function showTapeNotes(tape) {
        if (! tape) {
            alert('tape not exist. This shouldnt happen. Debug this');
            return;
        }
        setActiveTape(tape);
        setTapeNameText(tape.name);
        setNotesShowing(true);
        dispatch({ type: SET_TASK_TAPE, payload: tape.tapeUuid });
        dispatch({ type: SET_TASK_VIEW, payload: tape.tapeAnchorUuid });
        dispatch({ type: SET_AREA, payload: tape.locationUuid });
        updateDeepLink(history, deepLinkIds);
    }

    function hideTapeNotes() {
        setActiveTape({});
        dispatch({ type: SET_TASK_TAPE, payload: undefined });
        setTapeNameText('');
        setNotesShowing(false);
        updateDeepLink(history, deepLinkIds);
    }

    function validateTapeName() {
        return activeTape && tapeNameText.length > 2;
    }

    async function saveTapeName(event) {
        event.preventDefault();

        setIsSaving(true);

        try {
            activeTape.name = tapeNameText;
            setActiveTape(await api.putTape(activeTape));
            setIsSaving(false);
            setEditingTapeName(false);

        } catch (e) {
            onError(e);
            setIsSaving(false);
        }
    }

    async function setCompleted(completed, activeTape) {
        if (isUpdatingTaskComplete) {
            return;
        }
        try {
            setIsUpdatingTaskComplete(true);
            activeTape.taskCompleted = completed;
            setActiveTape(await api.putTape(activeTape));
            setIsUpdatingTaskComplete(false);
        }
        catch(e) {
            console.log("API Invocation failure!");
            console.error(e);
            setIsUpdatingTaskComplete(false);
            onError(e);
        }

    }

    function taskCompletedIcon(activeTape) {
        if (notesAuth.canMarkCompleted(activeTape)) {
            if (activeTape.taskCompleted) {
                //["action","disabled","error","inherit","primary","secondary"
                return (
                    <>
                        <CheckCircle fontSize="inherit" color="primary" style={{cursor: 'pointer'}}
                                     onClick={() => setCompleted(false, activeTape)} />
                        <Spinner animation="border" className={ !isUpdatingTaskComplete && "invisible"} />
                    </>
                );
            } else {
                return (
                    <>
                        <CheckCircleOutline fontSize="inherit" color="disabled" style={{cursor: 'pointer'}}
                                                onClick={() => setCompleted(true, activeTape)} />
                        <Spinner animation="border" className={ !isUpdatingTaskComplete && "invisible"} />
                    </>
                );
            }
        } else {
            if (activeTape.taskCompleted) {
                return (<CheckCircle fontSize="inherit" color="primary"/>);
            } else {
                return (<CheckCircleOutline fontSize="inherit" color="disabled"/>);
            }
        }
    }

    function renderSpinner() {
        if (taskViewImageLoaded) return null;
        return <Loading />;
    }

    function handleImageLoaded() {
        setTaskViewImageLoaded(true);
    }

    return (
        <>
            <style>
                {`

                body.modal-open .job-detail-modal {
                    background-image:url("../images/login-bg.png");
                    background-repeat:no-repeat;
                    background-size:cover;
                }

                `}
            </style>
            {renderSpinner()}
            <div style={{zIndex:1, border:"none"}}>
                <ResizedImage
                    url={taskView.image.contentUrl}
                    width={taskView.image.width * tapeImageRatio}
                    onLoad={() => handleImageLoaded()}
                />
            </div>
            {taskView.tapes.map((tape, tapeIndex) =>
                placeTape(tape, tapeIndex)
            )}
            {/* TODO: Make this a separate Notes component*/}
            <Modal
                show={notesShowing}
                onHide={hideTapeNotes}
                dialogClassName="modal-90w"
                size="xl" className="job-detail-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Spinner animation="border" className={ !isSaving && "invisible"} />
                    { editingTapeName &&
                        <div style={{width:"100%"}} className="center">
                            <Form>
                                <Form.Group controlId="tapeName" size="lg">
                                    <Form.Control
                                        autoFocus
                                        size="lg"
                                        type="text"
                                        onChange={e => setTapeNameText(e.target.value)}
                                        disabled={isSaving}
                                        value={tapeNameText}
                                    />
                                </Form.Group>
                                <LoaderButton
                                    size="md"
                                    type="save"
                                    variant="primary"
                                    isLoading={isSaving}
                                    disabled={!validateTapeName()}
                                    onClick={(e) => saveTapeName(e)}
                                >
                                    Save
                                </LoaderButton>

                                <LoaderButton
                                    size="md"
                                    type="save"
                                    variant="light"
                                    onClick={() => setEditingTapeName(false)}
                                >
                                    Cancel
                                </LoaderButton>
                            </Form>
                        </div>
                    }
                    { !editingTapeName &&

                        <div style={{width:"100%"}} className="center">
                            {/*<p>*/}
                                <span className="mx-2">
                                    {
                                        tapesAuth.canEdit(activeTape) &&
                                        <Edit fontSize="inherit" color="primary" style={{ cursor: 'pointer' }} onClick={() => setEditingTapeName(true)} />
                                    }
                                </span>
                                {activeTape.name}
                                <span className={"mx-2"}>
                                    {taskCompletedIcon(activeTape)}
                                </span>
                                - <span className="small" style={{textAlign: "right"}}>Created on: {new Date(activeTape.createdOn).toLocaleDateString()}</span>
                            {/*</p>*/}
                        </div>
                    }

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FeedContextProvider>
                        <Feed activeTape={activeTape} activeTaskView={taskView} />
                    </FeedContextProvider>
                </Modal.Body>
                {/*<Modal.Footer>*/}
                {/*    */}
                {/*</Modal.Footer>*/}
            </Modal>
        </>
    )
}
