import React, {useContext, useEffect, useState} from "react";
import "./Feed.css";
import {Alert, Col, Container, Row} from "react-bootstrap";
import Linkify from 'react-linkify';
import {FeedContext, useFeedContext} from "../../libs/feedContextLib";
import Form from "react-bootstrap/Form";
import LoaderButton from "../LoaderButton";
import NoteHeader from "./NoteHeader";
import {CANCEL_EDIT_NOTE, noteActions, PUT_NOTE, SELECT_NOTE} from "../../containers/tasktape/NoteInterfaceActions";
import {onError} from "../../libs/errorLib";
import _ from "lodash";

export default function TextNote(props) {

  const { feed, dispatch } = useContext(FeedContext);
  const [content, setContent] = useState(props.note.description);
  // const [note, setNote] = useState(props.note);

  // useEffect(() => { //IF no args then run only once per render I think?
  //     setNote(props.note);
  // }, [props.note, feed.activeNote, feed.activeReply])

  function noteValid() {
    return content?.length > 3;
  }

  async function saveNote(e) {
    e.preventDefault();
    props.note.description = content;
    dispatch({ type: PUT_NOTE, payload: props.note });

    try {
      const updatedNote = await noteActions.putNote(feed.activeNote);
      dispatch({type: SELECT_NOTE, payload: updatedNote});
    } catch (e) {
      console.error(e);
      onError("Unable to save note to server");
      dispatch({type: CANCEL_EDIT_NOTE});
    }

  }

  function cancelSaveNote(e) {
    e.preventDefault();
    dispatch({ type: CANCEL_EDIT_NOTE });
    //TODO: Look at moving this to NoteInterfaceActions?
    _.remove(feed.activeTape.notes["TEXT"], (iterNote) => iterNote.noteUuid === feed.activeNote.noteUuid);
  }

  return (
    <>
      <Row>
        <Col className="modal-title-main">
          <div className="modal-inner">
            <NoteHeader note={props.note} replyNote={props.replyNote} />
          </div>
        </Col>
      </Row>

      <Row>
        <Col className="modal-title-content">
          {(props.note.noteUuid !== feed.activeNote?.noteUuid || !feed.editingNote) &&
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          ><span style={{whiteSpace: "pre"}}>{props.note.description}</span></Linkify>
          }
          {props.note.noteUuid === feed.activeNote?.noteUuid && feed.editingNote &&
          <Form>
            <Form.Group controlId="replyText" size="lg">
              <Form.Control
                autoFocus
                as="textarea"
                rows={3}
                onChange={e => setContent(e.target.value)}
                placeholder="Type your note here"
                disabled={feed.saving}
                value={content}
              />
            </Form.Group>
            <LoaderButton
              size="md"
              type="save"
              variant="primary"
              isLoading={feed.saving}
              disabled={!noteValid()}
              onClick={(e) => saveNote(e)}
            >
              Save
            </LoaderButton>

            <LoaderButton
              size="md"
              type="save"
              variant="light"
              onClick={(e) => cancelSaveNote(e)}
            >
              Cancel
            </LoaderButton>

          </Form>
          }
        </Col>
      </Row>
    </>
  );
}
/**
 {
                                            "content": [],
                                            "tapeUuid": "BE47A2C1-FD81-4025-823E-DB06A9EDF535",
                                            "version": 0,
                                            "createdUserLastName": "Lover",
                                            "createdOn": 1619245658584,
                                            "createdUserFirstName": "John ",
                                            "shareable": true,
                                            "description": "Message sent you got a blue bell ",
                                            "createdUser": "c1db9926-4a0a-4089-8dbf-ea667eae5434",
                                            "noteUuid": "A7BDEC52-C60C-46C6-9A6A-F6E560896737",
                                            "tags": [],
                                            "type": "TEXT",
                                            "replies": [
                                                {
                                                    "content": "Let me know if you see my replies and sendas. ",
                                                    "tapeUuid": "BE47A2C1-FD81-4025-823E-DB06A9EDF535",
                                                    "version": 5,
                                                    "readBy": [
                                                        "a25273a9-9c63-4fd0-9443-e7ddb52388fe",
                                                        "a25273a9-9c63-4fd0-9443-e7ddb52388fe",
                                                        "c1db9926-4a0a-4089-8dbf-ea667eae5434",
                                                        "c1db9926-4a0a-4089-8dbf-ea667eae5434",
                                                        "ba41452a-f770-4057-ae40-6f3117d682a1"
                                                    ],
                                                    "createdUserFirstName": "John",
                                                    "jobOwnerUuid": "a25273a9-9c63-4fd0-9443-e7ddb52388fe",
                                                    "createdUser": "a25273a9-9c63-4fd0-9443-e7ddb52388fe",
                                                    "noteUuid": "A7BDEC52-C60C-46C6-9A6A-F6E560896737",
                                                    "deleted": false,
                                                    "replyUuid": "29CEE9DC-4961-4866-81FC-5AB68FF19694",
                                                    "createdUserLastName": "H",
                                                    "createdOn": 1619245852648,
                                                    "edited": false,
                                                    "jobUuid": "8A675488-5A2B-472A-9564-0E395145EB18",
                                                    "locationUuid": "8EC21CF7-9A21-4E67-9F4F-034DACFB459B"
                                                }
                                            ]
                                        }
 **/