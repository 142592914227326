import {onError} from "../../libs/errorLib";
import _ from "lodash";
import {v4 as uuidv4} from "uuid";
import Api from "../../libs/api";
import Note from "../../components/notes/Note";
import {constants} from "../../libs/models";

const SELECT_TAPE = 'SELECT_TAPE';
const SELECT_NOTE = 'SELECT_NOTE';
const PUT_NOTE = 'PUT_NOTE';
const EDIT_NOTE = 'EDIT_NOTE';
const CANCEL_EDIT_NOTE = 'CANCEL_EDIT_NOTE';
const NEW_NOTE = 'NEW_NOTE';
const DELETE_NOTE = 'DELETE_NOTE';
const PUT_REPLY = 'PUT_REPLY';
const EDIT_REPLY = 'EDIT_REPLY';
const CANCEL_EDIT_REPLY = 'CANCEL_EDIT_REPLY';
const NEW_REPLY = 'NEW_REPLY';
const DELETE_REPLY = 'DELETE_REPLY';
const api = Api();

const noteActions = {
  state: {}
};

noteActions.deleteNote = async function (note) {

  await api.deleteNote(note, noteActions.state.activeTape.locationUuid)//TODO: Is this the right way to handle an error here ?!
    .catch((e) => {
      console.error(e);
      onError(e);
    });

  _.remove(noteActions.state.activeTape.notes[note.type], (iterNote) => iterNote.noteUuid === note.noteUuid)

}

noteActions.newNote = function (noteType) {
  //Create the note data
  const newNote = {}

  standardNoteFields(newNote, noteType);

  if (noteActions.state.activeTape.notes[newNote.type] === undefined) {
    noteActions.state.activeTape.notes[newNote.type] = [];
  }

  //Add the new note to the dom
  noteActions.state.activeTape.notes[newNote.type].push(newNote);
  return newNote;
}

function standardNoteFields(note, type) {
  note.createdOn = Date.now(); //TODO: All dates to UTC and conversions to local
  note.createdUser = noteActions.state.user.userUuid;
  note.type = type;
  note.noteUuid = constants.DEFAULT_UNSAVED_UUID;
  note.replies = [];
  note.tapeUuid = noteActions.state.activeTape.tapeUuid;
  note.shareable = true;
  switch(type) {
    case "TEXT":
      note.content = "";
      break;
    case "IMAGES":
      note.content =[];
      break;
    default:
      note.content = "";
  }

}

noteActions.putNote = async function (note) {

  let isUpdate = true;
  if (!note.noteUuid) {  //Insert not update case
    note.noteUuid = uuidv4();
    isUpdate = false;
  }

  const updatedNote = await api.putNote(note, noteActions.state.activeTape.locationUuid);
  if (isUpdate) {
    //Do we have to update here?
  } else {
    noteActions.state.activeTape.notes[updatedNote.type].push(updatedNote); //TODO: Is this needed?
  }
  return updatedNote;
}

noteActions.putReply = async function (reply) {
  await api.putReply(reply);
}

noteActions.deleteReply = async function (reply) {
  await api.deleteReply(reply);
}

export {
  SELECT_TAPE,
  SELECT_NOTE,
  PUT_NOTE,
  EDIT_NOTE,
  CANCEL_EDIT_NOTE,
  NEW_NOTE,
  DELETE_NOTE,
  PUT_REPLY,
  EDIT_REPLY,
  CANCEL_EDIT_REPLY,
  NEW_REPLY,
  DELETE_REPLY,
  noteActions
};