import React from "react";
import Profile from "./Profile";
import {useAppContext} from "../../libs/contextLib";

export default function User() {

    const { user } = useAppContext();

    console.log(`Profile page: user: ${JSON.stringify(user)}`);

    return (
        <Profile />
    );
}
