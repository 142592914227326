import React, {useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Dropdown, Image, Modal, Table} from "react-bootstrap";
import ResizedImage from "./ResizedImage";
import TTAvatar from "./TTAvatar";
import {Link, useHistory} from "react-router-dom";
import Moment from 'react-moment';
import {useAppContext} from "../libs/contextLib";
import config from '../config';
import Images from '../libs/image';
import {Edit} from "@material-ui/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ShareJob from "./ShareJob";
import "./JobListTable.css";

export default function JobsList(props) {

    const [showJobImage, setShowJobImage] = useState(null);
    const [showJobShare, setShowJobShare] = useState(null);
    const { user } = useAppContext();
    const history = useHistory();
    const images = Images();

    const handleImageModalClose = () => {
        setShowJobImage(null);
    }
    const handleImageModalShow = (id) => {
        setShowJobImage(id);
    }

    const handleShareModalClose = () => {
        setShowJobShare(null);
    }
    const handleShareModalShow = (id) => {
        setShowJobShare(id);
    }

    //SHould we move this somwhere re-usable?
    function buildUserForAvatar(job) {
        return {
            firstName: job.createdUserFirstName,
            lastName: job.createdUserLastName,
            userUuid: job.createdUser,
            profile: {
                profile_image_url: `https://${config.aws.s3JobBucket}/users/${images.getProfileImageStorageKey(job.createdUser)}`
            }
        }
    }

    return(
        <Table bordered hover>
            <thead>
            <tr>
               
                <th className="list-m-hide-title">Menu</th>
                <th className="list-m-hide">Edit</th>
                <th></th>
                <th className="list-m-hide">Created</th>
                <th>Job Name</th>
                <th className="list-m-hide">Share</th>
                <th className="list-m-hide">Location</th>
                <th className="list-m-hide">Download</th>
            </tr>
            </thead>
            <tbody>
            {props.jobs.map(job =>
                <tr key={job.jobUuid}>
                    {/*Mobile representation of this cell has "burger menu" for missing column actions*/}
                    <td className="mobile-view-td">
                        <Dropdown className="mobile-view-list">
                          <Dropdown.Toggle>
                              <FontAwesomeIcon icon="bars" color="#1a67d3" style={{cursor: "pointer"}} size="1x"/>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>
                                <td>
                                    {job.createdUser === user.userUuid &&
                                        <Edit fontSize="inherit" color="primary" style={{ cursor: 'pointer' }} onClick={() => props.popJobEditModal(job.jobUuid)} />
                                    }
                                    {job.createdUser !== user.userUuid &&
                                        <img src="/icons/icons-handshake.png" srcSet="/icons/icons-handshake@2x.png 2x,/icons/icons-handshake@3x.png 3x" />
                                    }
                                    {/* Need to change above to overall security function delegate that also checks role*/}
                                </td>
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                                <td>{ job.address && job.address.geolocation && job.address.geolocation.lat > 0 &&
                                    <a href={'https://www.google.com/maps/search/?api=1&query=' + job.address.geolocation.lat + ',' + job.address.geolocation.lng} target="_blank">
                                        <FontAwesomeIcon icon="map-marked-alt" color="#1a67d3" style={{cursor: "pointer"}} size="1x"/>
                                    </a> }
                                    { ! (job.address && job.address.geolocation && job.address.geolocation.lat) > 0 && <span>NA</span> }
                                </td>
                            </Dropdown.Item>
                            <Dropdown.Item >
                                <FontAwesomeIcon icon="share-square" color="#1a67d3" style={{cursor: "pointer"}} size="1x"
                                                                 onClick={() => handleShareModalShow(job.jobUuid)}/>
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-5">
                                <td>
                                    <a href={`${config.apiGateway.URL}/jobs/${job.jobUuid}/pdf`} target="_blank">
                                    <FontAwesomeIcon icon="file-download" color="#1a67d3" style={{cursor: "pointer"}} size="1x"/></a>
                                </td>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                     </td>
                    {/*Standard representation of this cell*/}
                    <td className="list-m-hide">
                        {job.createdUser === user.userUuid &&
                            <Edit fontSize="inherit" color="primary" style={{ cursor: 'pointer' }} onClick={() => props.popJobEditModal(job.jobUuid)} />
                        }
                        {job.createdUser !== user.userUuid &&
                            <img src="/icons/icons-handshake.png" srcSet="/icons/icons-handshake@2x.png 2x,/icons/icons-handshake@3x.png 3x" />
                        }
                        {/* Need to change above to overall security function delegate that also checks role*/}
                    </td>
                    <td>
                        <Link to="#" className="rounded-circle-ancher" onClick={() => handleImageModalShow(job.jobUuid)}>
                            <ResizedImage
                                url={job.jobImage.contentUrl}
                                width={100}
                                roundedCircle={true}
                            />
                        </Link>
                        {/*https://react-bootstrap.github.io/components/modal/#modal-custom-sizing>*/}
                        <Modal
                            show={showJobImage === job.jobUuid}
                            onHide={handleImageModalClose}
                            dialogClassName="modal-90w"
                            size="xl"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>{job.name}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Image
                                    src={images.getUrlForOriginal(job.jobImage.contentUrl)}
                                    // style={{
                                    //     width: 100%
                                    // }}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                Taken on: {new Date(job.jobImage.createdOn).toLocaleDateString()}
                            </Modal.Footer>
                        </Modal>
                    </td>
                    <td className="list-m-hide">
                        <div>
                            <TTAvatar user={buildUserForAvatar(job)} />
                        </div>
                        <span style={ {fontSize: "12pt", whiteSpace: "nowrap", textAlign: "right", verticalAlign: "bottom"} }>
                            <Moment format="YYYY-MM-DD">{job.createdOn}</Moment>
                        </span>
                    </td>
                    <td>
                        <Link to={{
                            pathname: `/job/detail/${job.jobUuid}`,
                            state: { job_uuid: job.jobUuid }
                        }}> {job.name} </Link>
                    </td>
                    <td className="list-m-hide">
                        <FontAwesomeIcon icon="share-square" color="#1a67d3" style={{cursor: "pointer"}} size="1x"
                                         onClick={() => handleShareModalShow(job.jobUuid)}/>
                        <Modal
                            show={showJobShare === job.jobUuid}
                            onHide={handleShareModalClose}
                            dialogClassName="modal-90w"
                            size="md"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Sharing {job.name}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ShareJob userRoleInJob={job.userRoleInJob} jobUuid={job.jobUuid} jobName={job.name} closeModal={handleShareModalClose}  />
                            </Modal.Body>
                            <Modal.Footer
                                style={{
                                    justifyContent: "center",
                                    margin: "0 auto",
                                    width: "100%"
                                }}
                            >
                                <p>
                                On our desktop app, you can only share via email. Please enter email above and we'll send
                                them a notification to join this job. If you would like to share by Text Message (SMS)
                                please:
                                </p>
                                <p style={{alignContent: "center"}}><a href="https://tasktape.com" target="_new">Download our mobile app</a></p>
                            </Modal.Footer>
                        </Modal>
                    </td>
                    <td className="list-m-hide">{ job.address && ((job.address.geolocation && job.address.geolocation.lat > 0) || job.address.validated ) &&
                        <a href={'https://www.google.com/maps/search/?api=1&query=' + encodeURI(job.address.streetAddress + ',' + job.address.city + ',' + job.address.state + ',' + job.address.postCode + ',' + job.address.country) + '&query=' + job.address.geolocation.lat + ',' + job.address.geolocation.lng} target="_new">
                            <FontAwesomeIcon icon="map-marked-alt" color="#1a67d3" style={{cursor: "pointer"}} size="1x"/>
                        </a> }
                        { ! (job.address && job.address.geolocation && job.address.geolocation.lat) > 0 && <span>NA</span> }
                    </td>
                    <td className="list-m-hide">
                        <a href={`${config.apiGateway.URL}/jobs/${job.jobUuid}/pdf`} target="_new">
                        <FontAwesomeIcon icon="file-download" color="#1a67d3" style={{cursor: "pointer"}} size="1x"/></a>
                    </td>
                </tr>
            )
            }
            </tbody>
        </Table>
    )
}