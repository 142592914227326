import {useJobContext} from "./jobContextLib";
import {useAppContext} from "./contextLib";

function Authorization() {

    const { user } = useAppContext();
    const { jobDetail } = useJobContext();
    const OWNER = "ROLE_OWNER";
    const VIEWER = "ROLE_VIEWER";

    const functions = {};

    const replyAuth = {};
    const notesAuth = {};
    const tapesAuth = {};

    functions.replies = replyAuth;
    functions.notes = notesAuth;
    functions.tapes = tapesAuth;

    notesAuth.canCreate = function() {
        return jobDetail.userRoleInJob === OWNER;
    }

    notesAuth.canMarkCompleted = function(activeTape) {
        return activeTape.createdUser === user.userUuid || jobDetail.userRoleInJob === OWNER;
    }

    notesAuth.canEdit = function(note) {
        return note.createdUser === user.userUuid;
    }

    notesAuth.canDelete = function(note) {
        return note.createdUser === user.userUuid || jobDetail.createdUser === user.userUuid;
    }

    replyAuth.canEdit = function (reply) {
        return reply.createdUser === user.userUuid;
    }

    replyAuth.canDelete = function (reply) {
        return reply.createdUser === user.userUuid || jobDetail.userRoleInJob === OWNER;
    }

    tapesAuth.canEdit = function(tape) {
        return tape.createdUser === user.userUuid || jobDetail.userRoleInJob === OWNER;
    }

    return functions;

}

export default Authorization;