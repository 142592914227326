import {Image} from "react-bootstrap";
import React from "react";
import Images from "../libs/image"

export default function ResizedImage(props) {

    const images = Images();

    if (! props.url) { //Dummy image
        return(
            <Image src="/icons/tape-icon-drag@3x.png"
                   style={{
                       width: props.width,
                       height: props.height,
                       opacity: 0.2
                   }} />
        )
    }

    //TODO: This needs a loading spinner
    return(
        <Image src={images.getUrlForResizedImage(props.url, props.width, props.height)}
               style={{
                   width: props.width,
                   height: props.height
               }}
               fluid={props.fluid || false}
               key={props.url}
               thumbnail={props.thumbnail || false}
               roundedCircle={props.roundedCircle || false}
               onLoad={props.onLoad && props.onLoad()}
        />
    )
}
